// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'DEV',
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: 'AIzaSyAqKiGUsGZ8kNPSvcmuV24jWFdmT0sikm8',
    authDomain: 'avant-dev-357711.firebaseapp.com',
    projectId: 'avant-dev-357711',
    storageBucket: 'avant-dev-357711.appspot.com',
    messagingSenderId: '572150162522',
    appId: '1:572150162522:web:27dbd88cbd0b75ba7d6463',
    measurementId: 'G-SGXLHW2DVE',
  },
  GOOGLE_AUTH_CLIENT_ID:
    '572150162522-c2gn847rv03h765r0rfm1k3mo3l22ssv.apps.googleusercontent.com',
  backendUrl: 'https://devavantapi-hk3jrpshfq-uc.a.run.app/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
