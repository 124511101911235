import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';

// Swiper Slider
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

// Counter
import { CountToModule } from 'angular-count-to';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';

import { ScrollspyDirective } from './scrollspy.directive';

// NFT Landing
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SysAdminModalComponent } from './sys-admin-modal/sys-admin-modal.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { AvatarModule } from 'ngx-avatar-ng13';
import { UserProfileComponent } from './profile/user-profile.component';
import { SharedUiModule } from '../shared-ui/shared-ui.module';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    ScrollspyDirective,
    PaginationComponent,
    SysAdminModalComponent,
    ResetPasswordModalComponent,
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgxUsefulSwiperModule,
    NgbPaginationModule,
    CountToModule,
    ReactiveFormsModule,
    FormsModule,
    AvatarModule,
    SharedUiModule,
  ],
  exports: [
    BreadcrumbsComponent,
    PaginationComponent,
    ScrollspyDirective,
    SysAdminModalComponent,
    ResetPasswordModalComponent,
  ],
})
export class SharedModule {}
