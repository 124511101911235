<div>
  <label for="studentAutocomplete" class="text-muted">{{label}}</label>
  <ng-autocomplete
      id="studentAutocomplete"
      #auto
      [data]="studentArrForView"
      [searchKeyword]="keyword"
      [placeholder]="placeholder"
      (selected)='selectEvent($event)'
      (inputChanged)='onChangeSearch($event)'
      (inputFocused)='onFocused($event)'
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate">
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
      <a [innerHTML]="item.firstName"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
  </ng-template>
</div>