import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from 'src/app/core/models/site.models';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sys-admin-modal',
  templateUrl: './sys-admin-modal.component.html',
  styleUrls: ['./sys-admin-modal.component.scss'],
})
export class SysAdminModalComponent implements OnInit {
  adminForm: FormGroup;
  submitted = false;
  @Input() schoolData: Site;
  @ViewChild('content')
  private modalContent!: TemplateRef<SysAdminModalComponent>;
  @Output() getFormEvent = new EventEmitter<any>();
  @Input() modalTitle: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.adminForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      sendActivationEmail: [false],
    });
  }

  open(adminId?: any, site?: Site): Promise<boolean> {
    if (adminId) {
      this.fetchAdmin(adminId);
      this.adminForm.controls['email'].disable();
    }
    if (site) {
      this.schoolData = site;
    }

    return new Promise<boolean>((resolve) => {
      this.modalService.open(this.modalContent, { size: 'sm', centered: true });
    });
  }

  /**
   * Form data get
   */
  get form() {
    return this.adminForm.controls;
  }
  /**
   * Create Admin
   */
  async setAdmin() {
    if (this.adminForm.valid) {
      this.getFormEvent.emit(this.adminForm);
      this.modalService.dismissAll();
    }
    this.submitted = true;
  }

  /**
   * Fetches Admin by Id
   */
  private fetchAdmin(id: any) {
    this.userService.getUserById(id).subscribe((data) => {
      const adminData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        sendActivationEmail: data.sendActivationEmail
      };
      this.adminForm.patchValue(adminData);
    });
  }
}
