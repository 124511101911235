<div class="row px-3 justify-content-md-between align-items-center g-0">
  <div class="col col-sm-6">
    <div
      class="dataTables_info mb-2"
      id="tickets-table_info"
      role="status"
      aria-live="polite"
    >
      Showing
      <!-- {{ service.startIndex }} to {{ service.endIndex }} of
                      {{ service.totalRecords }} -->
      {{ pageSize * (page - 1) + 1 }} to {{ pageSize * page }} of
      {{ collectionSize }} entries
    </div>
  </div>
  <!-- Pagination -->
  <div class="col col-sm-6">
    <div class="text-sm-right float-end pagination-rounded gridjs-pagination">
      <ngb-pagination
        [collectionSize]="collectionSize"
        [pageSize]="pageSize"
        [page]="page"
        (pageChange)="pageChange($event)"
      >
      </ngb-pagination>
    </div>
    <!-- End Pagination -->
  </div>
</div>
