<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5>{{ modalTitle }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form (ngSubmit)="setAdmin()" [formGroup]="adminForm">
    <div class="modal-body">
      <div class="row g-2">
        <div class="col-lg-12">
          <div class="form-floating">
            <input type="text" class="form-control" id="schoolName" [value]="schoolData.name" readonly>
            <label for="schoolName" class="form-label">School Name</label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="firstName"
              required
              formControlName="firstName"
              placeholder="First Name"
              [ngClass]="{
                'is-invalid': submitted && form['firstName'].errors
              }"
            />
            <label for="firstName">First Name</label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="lastName"
              required
              formControlName="lastName"
              placeholder="Last Name"
              [ngClass]="{
                'is-invalid': submitted && form['lastName'].errors
              }"
            />
            <label for="lastName">Last Name</label>
          </div>
        </div>
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="emailAdress"
            required
            formControlName="email"
            placeholder="Email Adress"
            [ngClass]="{
              'is-invalid': submitted && form['email'].errors
            }"
          />
          <label for="emailAdress">Email Address</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="formCheck6"
            formControlName="sendActivationEmail"
            [ngClass]="{
              'is-invalid': submitted && form['sendActivationEmail'].errors
            }"
          />
          <label class="form-check-label" for="formCheck6">
            Send Invite Email to User
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="modal.close('Close click')"
        >
          Close
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">
          Save
        </button>
      </div>
    </div>
  </form>
  </ng-template>
  