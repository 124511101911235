import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAuthorizationGuard } from '../core/guards/user-authorization.guard';
import { Role } from '../core/models/enum';

// Component pages
import { JarvisLayoutComponent } from './jarvis/jarvis-layout/jarvis-layout.component';
import { NurseLayoutComponent } from './nurse/nurse-layout/nurse-layout.component';
import { ParentLayoutComponent } from './parent/parent-layout/parent-layout.component';

const routes: Routes = [
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./tasks/tasks.module').then((m) => m.TasksModule),
  },
  {
    path: 'ui',
    loadChildren: () => import('./ui/ui.module').then((m) => m.UiModule),
  },
  {
    path: 'forms',
    loadChildren: () => import('./form/form.module').then((m) => m.FormModule),
  },
  {
    path: 'tables',
    loadChildren: () =>
      import('./tables/tables.module').then((m) => m.TablesModule),
  },
  {
    path: 'icons',
    loadChildren: () =>
      import('./icons/icons.module').then((m) => m.IconsModule),
  },
  {
    path: 'parent',
    component: ParentLayoutComponent,
    loadChildren: () =>
      import('./parent/parent.module').then((m) => m.ParentModule),
    canActivate: [UserAuthorizationGuard],
    data: {
      role: Role.PARENT,
    },
  },
  {
    path: 'jarvis',
    component: JarvisLayoutComponent,
    loadChildren: () =>
      import('./jarvis/jarvis.module').then((m) => m.JarvisModule),
    canActivate: [UserAuthorizationGuard],
  },
  {
    path: 'nurse',
    component: NurseLayoutComponent,
    loadChildren: () =>
      import('./nurse/nurse.module').then((m) => m.NurseModule),
    canActivate: [UserAuthorizationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
