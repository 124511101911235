<div class="row">
  <div class="">
    <div class="card">
      <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">Top Schools</h4>
        <div class="flex-shrink-0">
          <div class="dropdown card-header-dropdown" ngbDropdown>
            <a
              class="text-reset dropdown-btn arrow-none"
              href="javascript: void(0);"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              <span class="fw-semibold text-uppercase fs-12">Sort by: </span
              ><span class="text-muted"
                >Today<i class="mdi mdi-chevron-down ms-1"></i
              ></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript: void(0);">Today</a>
              <a class="dropdown-item" href="javascript: void(0);">Yesterday</a>
              <a class="dropdown-item" href="javascript: void(0);"
                >Last 7 Days</a
              >
              <a class="dropdown-item" href="javascript: void(0);"
                >Last 30 Days</a
              >
              <a class="dropdown-item" href="javascript: void(0);"
                >This Month</a
              >
              <a class="dropdown-item" href="javascript: void(0);"
                >Last Month</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end card header -->

      <div class="card-body">
        <div class="table-responsive table-card">
          <table
            class="table table-hover table-centered align-middle table-nowrap mb-0"
          >
            <tbody>
              <tr *ngFor="let data of TopSchools">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm bg-light rounded p-1 me-2">
                      <img
                        src="{{ data.image }}"
                        alt=""
                        class="img-fluid d-block"
                      />
                    </div>
                    <div>
                      <h5 class="fs-13 my-1">
                        <a
                          href="/ecommerce/product-detail/1"
                          class="text-reset"
                          >{{ data.name }}</a
                        >
                      </h5>
                      <span class="text-muted">{{ data.address }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">{{ data.totalStudents }}</h5>
                  <span class="text-muted">Students</span>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">{{ data.documents }}</h5>
                  <span class="text-muted">Documents</span>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">
                    {{ data.answers }}
                  </h5>
                  <span class="text-muted">Questions Answered</span>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">{{ data.forms }}</h5>
                  <span class="text-muted">Forms</span>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">{{ data.tickets }}</h5>
                  <span class="text-muted">Tickets</span>
                </td>
                <td>
                  <h5 class="fs-14 my-1 fw-normal">
                    {{ data.medications }}
                  </h5>
                  <span class="text-muted">Medication</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="align-items-center mt-4 pt-2 justify-content-between d-flex"
        >
          <div class="flex-shrink-0">
            <div class="text-muted">
              Showing <span class="fw-semibold">5</span> of
              <span class="fw-semibold">25</span> Results
            </div>
          </div>
          <ul class="pagination pagination-separated pagination-sm mb-0">
            <li class="page-item disabled">
              <a href="javascript: void(0);" class="page-link">←</a>
            </li>
            <li class="page-item">
              <a href="javascript: void(0);" class="page-link">1</a>
            </li>
            <li class="page-item active">
              <a href="javascript: void(0);" class="page-link">2</a>
            </li>
            <li class="page-item">
              <a href="javascript: void(0);" class="page-link">3</a>
            </li>
            <li class="page-item">
              <a href="javascript: void(0);" class="page-link">→</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- .col-->
</div>
