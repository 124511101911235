import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-layout',
  templateUrl: './nurse-layout.component.html',
})
export class NurseLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
