import {
  Component,
  ViewChild,
  forwardRef,
  Input,
  TemplateRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, } from '@angular/forms';
import { UploadedFile } from 'src/app/pages/parent/student-layout/student-layout.type';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileCardComponent),
      multi: true,
    },
  ],
})
export class FileCardComponent implements OnInit {
  @Output("onDelete") onDelete: EventEmitter<any> = new EventEmitter();
  @Input() fileObject: UploadedFile;

  ngOnInit(): void {
  }

  delete() {
    this.onDelete.emit(this.fileObject);
  }

}
