import { Component, OnInit, Output, TemplateRef, ViewChild,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  adminForm!: FormGroup;
  submitted = false;
  @ViewChild('content')
  private modalContent!: TemplateRef<UserModalComponent>;
  @Output() getFormEvent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,private modalService: NgbModal) { }

  ngOnInit(): void {

    this.adminForm = this.formBuilder.group({
      school: ['', [Validators.required]],
      role: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  /**
   * Open modal
   * @param content modal content
   */
   openModal(content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: 'md', centered: true });
  }

   /**
   * Form data get
   */
    get form() {
      return this.adminForm.controls;
    }


    open(userId?: any): Promise<boolean> {
      console.log("admin id",userId)
  
      return new Promise<boolean>((resolve) => {
        this.modalService.open(this.modalContent, { size: 'sm', centered: true });
      });
    }
  async createUser() {
    if (this.adminForm.valid) {
      console.log(this.adminForm);
    }
    this.submitted = true;
  }

}
