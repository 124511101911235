import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from 'src/app/core/models/site.models';
import { User } from 'src/app/core/models/user.models';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnInit {
  pwForm: FormGroup;
  submitted = false;
  @Input() user: User;
  @ViewChild('content')
  private modalContent!: TemplateRef<ResetPasswordModalComponent>;
  @Output() getFormEvent = new EventEmitter<any>();
  @Input() modalTitle: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.pwForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }

  open(user: User): Promise<boolean> {
    this.user = user;

    return new Promise<boolean>((resolve) => {
      this.modalService.open(this.modalContent, { size: 'sm', centered: true });
    });
  }

  /**
   * Form data get
   */
  get form() {
    return this.pwForm.controls;
  }
  /**
   * Create Admin
   */
  async setAdmin() {
    if (this.pwForm.valid) {
      // this.getFormEvent.emit(this.pwForm);
      this.userService.resetUserPassword(this.user, this.pwForm.value.password)
      .subscribe((results) => {
        console.log('pw reset results: ', results);
        Swal.fire('Success', `${this.user.firstName} will be asked to reset their password on next login.`, 'success').finally(() => {
          this.modalService.dismissAll();
          this.pwForm.reset();
        });
      }, (err => {
        Swal.fire('Unable to set new password', err, 'error').finally(() => {
          this.modalService.dismissAll();
          this.pwForm.reset();
        });
      }));
    }
    this.submitted = true;
  }
}
