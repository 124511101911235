import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { states } from 'src/app/shared/constants';

@Component({
  selector: 'app-floating-input',
  templateUrl: './floating-input.component.html',
})
export class FloatingInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() readonly = false;
  @Input() required = false;
  @Input() showValidationErrors = false;
  @Input() mask: string = '';
  @Input() type:
    | 'text'
    | 'date'
    | 'email'
    | 'number'
    | 'password'
    | 'time'
    | 'states'
    | 'phoneNumber'
    | 'datetime-local' = 'text';
  @Input() id = `id-${Math.random().toString().replace('.', '')}-${Date.now()}`;
  @Input() classes = '';
  @Input() labelClasses = '';
  @Input() inputClasses = '';
  @Input() name = '';
  dropdownState: any;
  value = '';
  disabled = false;
  invalid = false;
  onChange: (val: string) => void;
  onTouched: () => void;
  allStates: { name: string; abbreviation: string }[];
  placeholder: string;

  constructor(@Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    this.placeholder = this.label;
    if (this.type === 'phoneNumber') {
      this.placeholder = '(xxx)xxx-xxxx';
      this.mask = '(000) 000-0000';
    }
    if (this.type === 'states') {
      this.allStates = states;
      if (this.value) {
        this.dropdownState = this.value;
      }
    }
    this.validateValue();
  }

  private validateValue() {
    this.invalid = (this.controlDir.invalid &&
      this.controlDir.dirty) as boolean;
  }

  writeValue(val: any): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  setValue(e: Event) {
    this.onChange((e.target as HTMLInputElement).value ?? '');
    this.validateValue();
  }
}
