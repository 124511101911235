import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Site } from '../models/site.models';
import { Student } from '../models/student.model';

const ACTIVE_SITE_KEY = 'activeSite';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private http: HttpClient) {}

  // TODO - move this to student service once it's there. 
  // didn't want to bother for now to avoid conflicts
  addStudentToSchool(siteId: number, studentInfo: Student) {
    return this.http.post<any>(`${environment.backendUrl}/student`, {
      siteId: siteId,
      student: studentInfo
    });
  }

  getFamiliesForSchool(siteId: number|string, params?: {
    skip?: number,
    take?: number,
    search?: string,
    orderByField?: string,
    orderByDirection?: string
  }): Observable<{ count: number, items: Student[]}> {
    return this.http.get<any>(`${environment.backendUrl}/sites/${siteId}/families`, {
      params
    })
  }

  getActiveSchool(defaultIfNull?: Site): Site|null {
    let site = localStorage.getItem(ACTIVE_SITE_KEY);
    if(site) return JSON.parse(site);
    else if(defaultIfNull) {
      this.setActiveSchool(defaultIfNull);
      return defaultIfNull;
    } else {
      return null;
    }
  }

  setActiveSchool(site: Site) {
    localStorage.setItem(ACTIVE_SITE_KEY, JSON.stringify(site));
  }

  getSchools(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/sites/query`);
  }

  createSchool(values: any): Observable<any> {
    console.log('foem in create school', values.get('file'));

    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post<any>(`${environment.backendUrl}/sites`, values);
  }

  getSchoolById(id: string|number, adminResponse = false): Observable<any> {
    let args = {};
    if(adminResponse) args = { params: { admin: adminResponse }};
    return this.http.get<any>(`${environment.backendUrl}/sites/` + id, args);
  }

  /***
   * Delete a School
   */
  deleteSchool(id: any): Observable<any> {
    return this.http.delete<any>(`${environment.backendUrl}/sites/` + id);
  }

  /***
   * Update a Site
   */
  updateSite(id: any, values: any): Observable<any> {
    return this.http.patch<Site>(
      `${environment.backendUrl}/sites/${id}`,
      values,
    );
  }

  /***
   * Update a Site
   */
  jarvisUpdateSite(id: any, values: any): Observable<any> {
    return this.http.patch<any>(
      `${environment.backendUrl}/sites/jarvis/${id}`,
      values,
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  }
}
