import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-top-schools',
  templateUrl: './top-schools.component.html',
  styleUrls: ['./top-schools.component.scss'],
})
export class TopSchoolsComponent implements OnInit {
  // Best Selling data
  @Input() TopSchools:
    | Array<{
        image: string;
        name: string;
        address: string;
        totalStudents: string;
        documents: number;
        answers: number;
        forms: number;
        tickets: number;
        medications: number;
      }>
    | undefined;

  constructor() {}

  ngOnInit(): void {}
}
