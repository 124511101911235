import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  AnswerYesNo,
  HealthQuestion,
  HealthQuestionAnswers,
  SaveObject,
  UploadedFile,
} from 'src/app/pages/parent/student-layout/student-layout.type';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  deleteIdFromCreateAndUpdateInSaveObject,
  removeUnfilledValuesFromData,
  transformHealthQuestions,
} from 'src/app/pages/parent/student-layout/student-layout.utils';
import { Student } from '../models/student.model';

const jsonToFormData = (jsonData: any) => {
  const formData = new FormData();
  for (const key in jsonData) {
    console.log(key, jsonData[key]);
    formData.append(key, jsonData[key]);
  }
  return formData;
};

const uploadDataAndFilesListAsFormData = (data: string, files: any) => {
  const formData = new FormData();
  formData.append('data', data);
  files.forEach((f: File) => {
    formData.append('files', f);
  });
  return formData;
};

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  constructor(private http: HttpClient) { }
  private _studentRefresh$ = new Subject<void>();

  get studentRefresh$() {
    return this._studentRefresh$;
  }

  findStudentsForSiteByName(args: {
    siteId: number,
    search?: string
  }) {
    let { siteId, search } = args;
    let params: { siteId: number, search?: string } = { siteId };
    if (search) params.search = search;
    return this.http.get<Student[]>(
      `${environment.backendUrl}/student/search`, {
      params
    }
    );
  }

  getChatPrompt(id: string | number) {
    return this.http.get<any>(
      `${environment.backendUrl}/student/${id}/chatgpt`,
    );
  }

  getHealthRecordProgress(id: string) {
    return this.http.get<any>(
      `${environment.backendUrl}/student/health-record-progress/${id}`,
    );
  }

  fetchStudent(id: string) {
    return this.http.get<any>(`${environment.backendUrl}/student/${id}`);
  }

  updateAllergyData(
    school_id: number,
    healthRecordId: number,
    allergyAnswer: AnswerYesNo | null,
    data: SaveObject,
  ) {
    deleteIdFromCreateAndUpdateInSaveObject(data);
    removeUnfilledValuesFromData(data);
    return this.http
      .patch<any>(`${environment.backendUrl}/student/${school_id}/allergy`, {
        healthRecordId,
        meta: {
          allergyAnswer,
        },
        ...data,
      })
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateOtcMedicationData(
    school_id: number,
    healthRecordId: number,
    data: any,
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/otc-medication`,
        jsonToFormData({
          files: data.files,
          data: JSON.stringify({
            healthRecordId,
            otcMedication: {
              tags: data.tags,
              comments: data.comments,
            },
          }),
        }),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateConsentForTreatmentData(
    school_id: number,
    healthRecordId: number,
    data: any,
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/consent-for-treatment`,
        jsonToFormData({
          files: data.files,
          data: JSON.stringify({
            healthRecordId,
            // consentForTreatment: {
            //   startDate: data.startDate,
            //   endDate: data.endDate,
            // },
          }),
        }),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  getAthleticPhysicalByStudentId(studentId: number) {
    return this.http
      .get<any>(
        `${environment.backendUrl}/student/${studentId}/athletic-physical`,
      )
  }

  uploadStudentCSVBulk(siteId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('siteId', siteId);
    return this.http
      .post<any>(`${environment.backendUrl}/student/upload`, formData)
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  saveAthleticPhysical(
    school_id: number,
    healthRecordId: number,
    athleticPhysicalAnswer: AnswerYesNo | null,
    deletedFiles: UploadedFile[],
    newFiles: File[],
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/athletic-physical`,
        uploadDataAndFilesListAsFormData(
          JSON.stringify({
            healthRecordId,
            meta: {
              athleticPhysicalAnswer,
            },
            deleted: deletedFiles
          }),
          newFiles,
        ),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  addSchoolHealthPhysicalDocuments(
    school_id: number,
    healthRecordId: number,
    files: any,
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/school-physical`,
        uploadDataAndFilesListAsFormData(
          JSON.stringify({ healthRecordId }),
          files,
        ),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  saveHealthInsuranceData(
    school_id: number,
    healthRecordId: number,
    data: any,
    files: any,
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/health-insurance`,
        uploadDataAndFilesListAsFormData(
          JSON.stringify({ healthRecordId, healthInsurance: data }),
          files,
        ),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  addImmunizationRecords(
    school_id: number,
    healthRecordId: number,
    files: any,
  ) {
    console.log(
      uploadDataAndFilesListAsFormData(
        JSON.stringify({ data: { healthRecordId, deleted: [] } }),
        files,
      ),
    );
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/immunization-record`,
        uploadDataAndFilesListAsFormData(
          JSON.stringify({ healthRecordId, deleted: [] }),
          files,
        ),
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateEmergencyContact(
    school_id: number,
    healthRecordId: number,
    data: SaveObject,
  ) {
    deleteIdFromCreateAndUpdateInSaveObject(data);
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/emergency-contact/`,
        {
          healthRecordId,
          ...data,
        },
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateMedications(
    school_id: number,
    healthRecordId: number,
    medicationsAnswer: AnswerYesNo | null,
    data: SaveObject,
  ) {
    deleteIdFromCreateAndUpdateInSaveObject(data);
    return this.http
      .patch<any>(`${environment.backendUrl}/student/${school_id}/medication`, {
        healthRecordId,
        meta: {
          medicationsAnswer,
        },
        ...data,
      })
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateHealthConditions(
    school_id: number,
    healthRecordId: number,
    healthConditionAnswer: AnswerYesNo | null,
    data: SaveObject,
  ) {
    deleteIdFromCreateAndUpdateInSaveObject(data);
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/health-condition`,
        {
          healthRecordId,
          meta: {
            healthConditionAnswer,
          },
          ...data,
        },
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateDemographics(school_id: number, data: any, addresses: SaveObject) {
    deleteIdFromCreateAndUpdateInSaveObject(addresses);
    data.dateOfBirth = data.dateOfBirth
      ? new Date(data.dateOfBirth).toISOString()
      : null;
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/demographics`,
        {
          demographics: {
            ...data,
            address: addresses,
          },
        },
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  updateHealthProviders(
    school_id: number,
    healthRecordId: number,
    data: SaveObject,
  ) {
    deleteIdFromCreateAndUpdateInSaveObject(data);
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/health-provider`,
        {
          healthRecordId,
          ...data,
        },
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }

  async fetchHealthQuestions(
    siteId: number,
    healthConditionAnswers: HealthQuestionAnswers[],
  ): Promise<HealthQuestion[]> {
    const data = await this.http
      .post<any>(`${environment.backendUrl}/student/health-question`, {
        siteId,
      })
      .toPromise();

    return transformHealthQuestions(data, healthConditionAnswers);
  }

  saveHealthQuestions(
    school_id: number,
    healthRecordId: number,
    saveHealthQuestionsList: any,
  ) {
    return this.http
      .patch<any>(
        `${environment.backendUrl}/student/${school_id}/health-questions`,
        {
          healthRecordId,
          healthQuestionAnswers: saveHealthQuestionsList,
        },
      )
      .pipe(tap(() => this.studentRefresh$.next()));
  }
}
