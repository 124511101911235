<table class="table table-borderless table-nowrap align-middle mb-0"> 
    <thead class="table-light text-muted">
        <tr>
            <th scope="col">Member</th>
            <th scope="col">Hours</th>
            <th scope="col">Tasks</th>
            <th scope="col">Status</th>
        </tr>
    </thead>                                                 
    <tbody>
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-1.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Donald Risher</h5>
                    <p class="fs-12 mb-0 text-muted">Product Manager</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">110h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                258
            </td>
            <td style="width:5%;">
                <apx-chart class="apex-charts" dir="ltr" [series]="status1.series" [chart]="status1.chart" [dataLabels]="status1.dataLabels"
                 [plotOptions]="status1.plotOptions" [colors]="status1.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-2.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Jansh Brown</h5>
                    <p class="fs-12 mb-0 text-muted">Lead Developer</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">83h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                105
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status2.series" [chart]="status2.chart" [dataLabels]="status2.dataLabels"
                 [plotOptions]="status2.plotOptions" [colors]="status2.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-7.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Carroll Adams</h5>
                    <p class="fs-12 mb-0 text-muted">Lead Designer</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">58h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                75
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status3.series" [chart]="status3.chart" [dataLabels]="status3.dataLabels"
                 [plotOptions]="status3.plotOptions" [colors]="status3.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-4.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">William Pinto</h5>
                    <p class="fs-12 mb-0 text-muted">UI/UX Designer</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">96h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                85
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status4.series" [chart]="status4.chart" [dataLabels]="status4.dataLabels"
                 [plotOptions]="status4.plotOptions" [colors]="status4.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-6.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Garry Fournier</h5>
                    <p class="fs-12 mb-0 text-muted">Web Designer</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">76h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                69
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status5.series" [chart]="status5.chart" [dataLabels]="status5.dataLabels"
                 [plotOptions]="status5.plotOptions" [colors]="status5.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-5.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Susan Denton</h5>
                    <p class="fs-12 mb-0 text-muted">Lead Designer</p>
                </div>
            </td>
            
            <td>
                <h6 class="mb-0">123h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                658
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status6.series" [chart]="status6.chart" [dataLabels]="status6.dataLabels"
                 [plotOptions]="status6.plotOptions" [colors]="status6.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
        <tr>
            <td class="d-flex">
                <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-xs rounded-3 me-2">
                <div>
                    <h5 class="fs-13 mb-0">Joseph Jackson</h5>
                    <p class="fs-12 mb-0 text-muted">React Developer</p>
                </div>
            </td>
            <td>
                <h6 class="mb-0">117h : <span class="text-muted">150h</span></h6>
            </td>
            <td>
                125
            </td>
            <td>
                <apx-chart class="apex-charts" dir="ltr" [series]="status7.series" [chart]="status7.chart" [dataLabels]="status7.dataLabels"
                 [plotOptions]="status7.plotOptions" [colors]="status7.colors"></apx-chart>
            </td>
        </tr><!-- end tr -->
    </tbody><!-- end tbody -->
</table><!-- end table -->