import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { Router, NavigationEnd, Route, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SchoolService } from 'src/app/core/services/school.service';

// Menu Pachage
// import MetisMenu from 'metismenujs';

import { AVANT_MENU } from './menu.avant';
import { JARVIS_MENU } from './menu.jarvis';
import { MenuItem } from './menu.model';
import { PARENT_MENU } from './menu.parent';
import { ProductModules } from 'src/app/core/models/site.models';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-horizontal-topbar',
  templateUrl: './horizontal-topbar.component.html',
  styleUrls: ['./horizontal-topbar.component.scss'],
})
export class HorizontalTopbarComponent implements OnInit {
  menu: any;
  menuItems: MenuItem[] = [];
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Input() configuration: string;
  siteId: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    public translate: TranslateService,
    private auth: AuthenticationService,
  ) {
    translate.setDefaultLang('en');
  }

  async ngOnInit() {
    let activeSchool = this.schoolService.getActiveSchool();
    if (!activeSchool) {
      let currentUser = this.auth.currentUser();
      let siteId = '-1';
      currentUser?.siteRoles.forEach(s => siteId = s.site.id);
      let school = await lastValueFrom(this.schoolService.getSchoolById(siteId));
      this.schoolService.setActiveSchool(school);
      activeSchool = this.schoolService.getActiveSchool();
      if (!activeSchool) throw Error(`No Active school and unsuccessfully set it after searching user sites (${currentUser?.sites.length})`);
    }
    if (!activeSchool.productModules) {
      let school = await lastValueFrom(this.schoolService.getSchoolById(activeSchool.id));
      this.schoolService.setActiveSchool(school);
      activeSchool = school;
    }
    if (!activeSchool) throw Error('No Active school');

    // Menu Items
    if (this.configuration === 'jarvis') this.menuItems = JARVIS_MENU;
    if (this.configuration === 'parent') this.menuItems = PARENT_MENU;
    if (this.configuration === 'nurse') this.menuItems = AVANT_MENU;

    this.menuItems = this.filterUnavailableModules(
      activeSchool.productModules,
      this.menuItems,
    );
    this.menuItems = this.replaceLinkParams(activeSchool.id, this.menuItems);
    
    this.route.params.subscribe(params => {
      if(this.siteId && params['siteId'] !== this.siteId) {
        console.log('setting new siteId param in URL.');
        console.log(`Old = ${this.siteId}, New = ${params['siteId']}`);
      }
      this.siteId = params['siteId'];
    });
  }

  onNewSiteIdNavigation() {

  }

  replaceLinkParams(activateSiteId: string, items: MenuItem[]) {
    function mapReplaceSiteIdInLink(m: MenuItem) {
      if (m.link) {
        m.link = m.link.replace(':siteId', activateSiteId);
      }
      if (m.subItems) {
        m.subItems = m.subItems.map(mapReplaceSiteIdInLink);
      }
      return m;
    }
    return items.map(mapReplaceSiteIdInLink);
  }

  filterUnavailableModules(
    productModules: ProductModules[],
    items: MenuItem[],
  ) {
    return items.filter((m) => {
      console.log('m: ' + m.link, m.requires);
      if (m.requires) {
        console.log('removing? ', productModules.indexOf(m.requires) > -1)
        return productModules.indexOf(m.requires) > -1;
      } else {
        return true;
      }
    });
  }

  /***
   * Activate droup down set
   */
  ngAfterViewInit() {
    this.initActiveMenu();
  }

  removeActivation(items: any) {
    items.forEach((item: any) => {
      if (item.classList.contains('menu-link')) {
        if (!item.classList.contains('active')) {
          item.setAttribute('aria-expanded', false);
        }
        item.nextElementSibling
          ? item.nextElementSibling.classList.remove('show')
          : null;
      }
      if (item.classList.contains('nav-link')) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show');
        }
        item.setAttribute('aria-expanded', false);
      }
      item.classList.remove('active');
    });
  }

  // remove active items of two-column-menu
  activateParentDropdown(item: any) {
    // navbar-nav menu add active
    item.classList.add('active');
    let parentCollapseDiv = item.closest('.collapse.menu-dropdown');
    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add('show');
      parentCollapseDiv.parentElement.children[0].classList.add('active');
      parentCollapseDiv.parentElement.children[0].setAttribute(
        'aria-expanded',
        'true',
      );
      if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
        parentCollapseDiv.parentElement
          .closest('.collapse')
          .classList.add('show');
        if (
          parentCollapseDiv.parentElement.closest('.collapse')
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest('.collapse')
            .previousElementSibling.classList.add('active');
        parentCollapseDiv.parentElement
          .closest('.collapse')
          .previousElementSibling.setAttribute('aria-expanded', 'true');
      }
      return false;
    }
    return false;
  }

  updateActive(event: any) {
    const ul = document.getElementById('navbar-nav');

    if (ul) {
      const items = Array.from(ul.querySelectorAll('a.nav-link'));
      this.removeActivation(items);
    }
    this.activateParentDropdown(event.target);
  }

  initActiveMenu() {
    const pathName = window.location.pathname;
    const ul = document.getElementById('navbar-nav');

    if (ul) {
      const items = Array.from(ul.querySelectorAll('a.nav-link'));
      let activeItems = items.filter((x: any) =>
        x.classList.contains('active'),
      );
      this.removeActivation(activeItems);
      let matchingMenuItem = items.find((x: any) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  toggleSubItem(event: any) {
    if (event.target && event.target.nextElementSibling)
      event.target.nextElementSibling.classList.toggle('show');
  }

  toggleItem(event: any) {
    let isCurrentMenuId = event.target.closest('a.nav-link');

    let isMenu = isCurrentMenuId.nextElementSibling as any;
    let dropDowns = Array.from(document.querySelectorAll('#navbar-nav .show'));
    dropDowns.forEach((node: any) => {
      node.classList.remove('show');
    });

    isMenu ? isMenu.classList.add('show') : null;

    const ul = document.getElementById('navbar-nav');
    if (ul) {
      const iconItems = Array.from(ul.getElementsByTagName('a'));
      let activeIconItems = iconItems.filter((x: any) =>
        x.classList.contains('active'),
      );
      activeIconItems.forEach((item: any) => {
        item.setAttribute('aria-expanded', 'false');
        item.classList.remove('active');
      });
    }
    if (isCurrentMenuId) {
      this.activateParentDropdown(isCurrentMenuId);
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }
}
