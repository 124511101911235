import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface FloatingSelectOptions {
  value: string | number | boolean;
  text: string;
}

@Component({
  selector: 'app-floating-select',
  templateUrl: './floating-select.component.html',
  styleUrls: ['./floating-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FloatingSelectComponent,
      multi: true,
    },
  ],
})
export class FloatingSelectComponent implements ControlValueAccessor {
  @Input('options') selectOptions: FloatingSelectOptions[] = [];
  @Input() classes = '';
  @Input() label = '';
  @Input() readonly = false;
  @Input() selectOptionTitle = 'Select Option';
  @Input() labelClasses = '';
  @Input() selectClasses = '';
  @Input() id = `id-${Math.random().toString().replace('.', '')}-${Date.now()}`;

  value = '';
  disabled = false;
  onChange: (val: string) => void;
  onTouched: () => void;

  writeValue(val: any): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  setValue(e: Event) {
    this.onChange((e.target as HTMLInputElement).value ?? '');
  }
}
