<header id="page-topbar" [ngClass]="{'jarvis-topbar': configuration == 'jarvis', 'parent-topbar': configuration == 'parent', 'nurse-topbar': configuration == 'nurse'}">
  <div class="layout-width">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div *ngIf="configuration == 'nurse'" class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17" />
            </span>
          </a>
        </div>
        <div *ngIf="configuration == 'jarvis'" class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/jarvis-logo-light.svg" alt="" height="17" />
            </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/jarvis-logo-light.svg" alt="" height="17" />
            </span>
          </a>
        </div>
        <div *ngIf="configuration == 'parent'" class="navbar-brand-box horizontal-logo">
          <a href="javascript:void(0);" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="javascript:void(0);" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="17" />
            </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
          id="topnav-hamburger-icon" (click)="toggleMobileMenu()">
          <span class="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-md-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." autocomplete="off" id="search-options"
              value="" />
            <span class="mdi mdi-magnify search-widget-icon"></span>
            <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
              id="search-close-options"></span>
          </div>
          <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
            <ngx-simplebar style="max-height: 320px">
              <!-- item-->
              <div class="dropdown-header">
                <h6 class="text-overflow text-muted mb-0 text-uppercase">
                  Recent Searches
                </h6>
              </div>

              <div class="dropdown-item bg-transparent text-wrap">
                <a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm btn-rounded">how to setup <i
                    class="mdi mdi-magnify ms-1"></i></a>
                <a href="javascript:void(0);" class="btn btn-soft-secondary btn-sm btn-rounded">buttons <i
                    class="mdi mdi-magnify ms-1"></i></a>
              </div>
              <!-- item-->
              <div class="dropdown-header mt-2">
                <h6 class="text-overflow text-muted mb-1 text-uppercase">
                  Pages
                </h6>
              </div>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                <span>Analytics Dashboard</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                <span>Help Center</span>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                <span>My account settings</span>
              </a>

              <!-- item-->
              <div class="dropdown-header mt-2">
                <h6 class="text-overflow text-muted mb-2 text-uppercase">
                  Members
                </h6>
              </div>

              <div class="notification-list">
                <!-- item -->
                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                  <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                  <div class="flex-1">
                    <h6 class="m-0">Angela Bernier</h6>
                    <span class="fs-11 mb-0 text-muted">Manager</span>
                  </div>
                </a>
                <!-- item -->
                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                  <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                  <div class="flex-1">
                    <h6 class="m-0">David Grasso</h6>
                    <span class="fs-11 mb-0 text-muted">Web Designer</span>
                  </div>
                </a>
                <!-- item -->
                <a href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                  <img src="assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                  <div class="flex-1">
                    <h6 class="m-0">Mike Bunch</h6>
                    <span class="fs-11 mb-0 text-muted">React Developer</span>
                  </div>
                </a>
              </div>
            </ngx-simplebar>

            <div class="text-center pt-3 pb-1">
              <a routerLink="/pages/search-results" class="btn btn-primary btn-sm">View All Results <i
                  class="ri-arrow-right-line ms-1"></i></a>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex align-items-center">
        <div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>
            <i class="bx bx-search fs-22"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <img *ngIf="flagvalue !== undefined" src="{{ flagvalue }}" alt="Header Language" height="20"
              class="rounded" />
            <img *ngIf="flagvalue === undefined" src="{{ valueset }}" alt="Header Language" height="20"
              class="rounded" />
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng"
              *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{ active: cookieValue === item.lang }">
              <img src="{{ item.flag }}" alt="user-image" class="me-2 rounded" height="18" />
              <span class="align-middle">{{ item.text }}</span>
            </a>
          </div>
        </div>

        <div class="ms-1 header-item d-none d-sm-flex">
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            data-toggle="fullscreen" (click)="fullscreen()">
            <i class="bx bx-fullscreen fs-22"></i>
          </button>
        </div>

        <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
          <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>
            <i class="bx bx-bell fs-22"></i>
            <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span
                class="visually-hidden">unread messages</span></span>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
            <div class="dropdown-head bg-primary bg-pattern rounded-top">
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0 fs-16 fw-semibold text-white">
                      Notifications
                    </h6>
                  </div>
                  <div class="col-auto dropdown-tabs">
                    <span class="badge badge-soft-light fs-13"> 4 New</span>
                  </div>
                </div>
              </div>

              <div class="px-2 pt-2">
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom"
                  id="notificationItemsTab">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink> All (4) </a>
                    <ng-template ngbNavContent>
                      <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                        <ngx-simplebar style="max-height: 300px">
                          <div class="pe-2">
                            <div class="text-reset notification-item d-block dropdown-item position-relative">
                              <div class="d-flex">
                                <div class="avatar-xs me-3">
                                  <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                    <i class="bx bx-badge-check"></i>
                                  </span>
                                </div>
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-2 lh-base">
                                      Your <b>Elite</b> author Graphic
                                      Optimization
                                      <span class="text-secondary">reward</span>
                                      is ready!
                                    </h6>
                                  </a>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i>
                                      Just 30 sec ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item position-relative">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Angela Bernier
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Answered to your comment on the cash flow
                                      forecast's graph 🔔.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 48
                                      min ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item position-relative">
                              <div class="d-flex">
                                <div class="avatar-xs me-3">
                                  <span class="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
                                    <i class="bx bx-message-square-dots"></i>
                                  </span>
                                </div>
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-2 fs-13 lh-base">
                                      You have received
                                      <b class="text-success">20</b> new
                                      messages in the conversation
                                    </h6>
                                  </a>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 2
                                      hrs ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item position-relative">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Maureen Gibson
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 4
                                      hrs ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="my-3 text-center">
                              <button type="button" class="btn btn-soft-success waves-effect waves-light">
                                View All Notifications
                                <i class="ri-arrow-right-line align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </ngx-simplebar>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink> Messages </a>
                    <ng-template ngbNavContent>
                      <div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                        <ngx-simplebar style="max-height: 300px">
                          <div class="pe-2">
                            <div class="text-reset notification-item d-block dropdown-item">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      James Lemire
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 30
                                      min ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Angela Bernier
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Answered to your comment on the cash flow
                                      forecast's graph 🔔.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 2
                                      hrs ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-6.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Kenneth Brown
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      Mentionned you in his comment on 📃
                                      invoice #12501.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 10
                                      hrs ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="text-reset notification-item d-block dropdown-item">
                              <div class="d-flex">
                                <img src="assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs"
                                  alt="user-pic" />
                                <div class="flex-1">
                                  <a href="javascript:void(0);" class="stretched-link">
                                    <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                      Maureen Gibson
                                    </h6>
                                  </a>
                                  <div class="fs-13 text-muted">
                                    <p class="mb-1">
                                      We talked about a project on linkedin.
                                    </p>
                                  </div>
                                  <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                    <span><i class="mdi mdi-clock-outline"></i> 3
                                      days ago</span>
                                  </p>
                                </div>
                                <div class="px-2 fs-15">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </div>
                            </div>

                            <div class="my-3 text-center">
                              <button type="button" class="btn btn-soft-success waves-effect waves-light">
                                View All Messages
                                <i class="ri-arrow-right-line align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </ngx-simplebar>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink> Alerts </a>
                    <ng-template ngbNavContent>
                      <div class="tab-pane p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                        <div class="w-25 w-sm-50 pt-3 mx-auto">
                          <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                        </div>
                        <div class="text-center pb-5 mt-2">
                          <h6 class="fs-18 fw-semibold lh-base">
                            Hey! You have no any notifications
                          </h6>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>

            <div class="tab-content" id="notificationItemsTabContent">
              <div class="tab-content text-muted">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
          <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <span class="d-flex align-items-center">
              <img *ngIf="user?.photoUrl" class="rounded-circle header-profile-user" src="{{user?.photoUrl}}">
              <ngx-avatar
              *ngIf="!user?.photoUrl"
                size="40"
                name="{{
                  user?.firstName?.charAt(0) +
                    ' ' +
                    user?.lastName?.charAt(0)
                }}"
              ></ngx-avatar>
              <span class="text-start ms-xl-2">
                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{user?.firstName}} {{user?.lastName}}</span>
                <!-- <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> -->
              </span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <h6 class="dropdown-header">Welcome {{user?.firstName}}!</h6>
            <a class="dropdown-item" routerLink="./profile"><i
                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">Profile</span></a>
            <!-- <a class="dropdown-item" routerLink="/apps/chat"><i
                                class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Messages</span></a>
                        <a class="dropdown-item" routerLink="/tasks/kanban"><i
                                class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Taskboard</span></a> -->
            <a class="dropdown-item" routerLink="/pages/faqs"><i
                class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">Help</span></a>
            <div class="dropdown-divider"></div>

<!-- <button type="button" class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#switchSchool">Standard Modal</button> -->
            <span *ngIf="!isOnlyAParent"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#switchSchool" (click)="openModal(content)">
              <i class="ri-shuffle-fill text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">Switch School</span>
            </a>
            <div class="dropdown-divider"></div>
            <a *ngIf="!!activeSchool" class="dropdown-item" routerLink="/nurse/{{activeSchool.id}}">
              <i class="ri-door-open-line text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">To Nurse Dashboard</span>
            </a>
            <a class="dropdown-item" routerLink="/parent">
              <i class="ri-door-open-line text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">To Family Dashboard</span>
            </a>
            <a *ngIf="!!adminRoleLength && adminRoleLength > 0" class="dropdown-item" routerLink="/jarvis">
              <i class="ri-door-open-line text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">To Jarvis Dashboard</span>
            </a>
            <div class="dropdown-divider"></div></span>
            <!-- <a class="dropdown-item" routerLink="/pages/profile"><i
                                class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Balance : <b>$5971.67</b></span></a>
                        <a class="dropdown-item" routerLink="/pages/profile"><span
                                class="badge bg-soft-success text-success mt-1 float-end">New</span><i
                                class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Settings</span></a> -->
            <a class="dropdown-item" routerLink="/auth/lockscreen/basic"><i
                class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle">Reset Password</span></a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="logout()"><i
                class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span class="align-middle" data-key="t-logout">Logout</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Select a school to switch accounts</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="card card-animate"  *ngFor="let item of user?.siteRoles; index as i">
      <div class="card-body" (click)="switchSite(item)">
        <div class="d-flex justify-content-between">
          <div>
            <!-- <p class="fw-medium text-muted mb-0">Riverside Highschool</p> -->
            <h4 class="mt-4 ff-secondary fw-semibold">
              <span
                class="counter-value"
              >{{item.site.name}}</span>
            </h4>
            <p class="mb-0 text-muted">
              <span
                class="badge bg-light text-success mb-0"
              >
                Role(s)
              </span>
              {{item.roles}}
            </p>
          </div>
          <div>
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-soft-info rounded-circle fs-2">
                <i-feather
                  name="external-link"
                  class="text-info feather-icon-align"
                ></i-feather>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card-->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
  </div>
</ng-template>