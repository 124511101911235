<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5>Reset {{ user.firstName }}'s Password</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      id="close-modal"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form (ngSubmit)="setAdmin()" [formGroup]="pwForm">
    <div class="modal-body">
      <div class="row g-2">
        <div class="col-lg-12">
          <div class="form-floating">
            <input
            type="password"
            class="form-control"
            formControlName="password"
            id="password"
            [ngClass]="{
              'is-invalid': submitted && form['password'].errors
            }"
            [value]="">
            <label for="password" class="form-label">New Password</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          (click)="modal.close('Close click')"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-success" id="add-btn">
          Reset Password
        </button>
      </div>
    </div>
  </form>
  </ng-template>
  