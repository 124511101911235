import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Role } from '../models/enum';
import { AuthenticationService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthorizationGuard implements CanActivate {
  constructor(private router: Router, private _auth: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const role = route?.data['role'] || null;
    switch (role) {
      case Role.PARENT:
        return true;
      default:
        // TODO: so if route do not have any role specified, call function here the will redirect user on based on his current role
        return true;
      // return this.router.navigate(['/parent']);
    }
  }
}
