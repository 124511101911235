// export const AddressType = Object.freeze({
//   SCHOOL: 'SCHOOL',
//   HOME: 'HOME',
// });
export enum AddressType {
  SCHOOL,
  HOME,
}

export enum SiteType {
  PUBLIC,
  PRIVATE,
}

export enum Role {
  SYSTEMADMIN,
  NURSE,
  PARENT,
  FACULTY,
  INTERNAL_EMPLOYEE,
  INTERNAL_ADMIN,
}
