import {
  Address,
  Allergy,
  HealthCondition,
  healthInsuranceDefault,
  HealthProvider,
  HealthQuestionAnswers,
  HealthQuestionType,
  HealthRecord,
  Medication,
  SaveObject,
  TrackObject,
} from './student-layout.type';

const transformAllergies = (allergies: any) => {
  return allergies.map((allergy: Allergy) => {
    const {
      id,
      allergen,
      name,
      severity,
      hasBeenHospitalized,
      dateOfLastReaction,
      medicationId,
      comments,
      typeOfReaction,
    } = allergy;
    return {
      id,
      allergen,
      name,
      severity,
      hasBeenHospitalized,
      medicationId,
      dateOfLastReaction,
      comments,
      typeOfReaction: typeOfReaction[0],
    };
  });
};

const transformAddressForDemographics = (addresses: any) => {
  return addresses.map((address: Address) => {
    const { id, street, street2, state, city, zipCode } = address;
    return {
      id,
      street,
      street2,
      state,
      city,
      zipCode,
      type: 'HOME',
    };
  });
};

const transformAddressForHealthProvider = (address: any) => {
  const { id, street, state, city, zipCode, type } = address;
  return {
    id,
    street,
    state,
    city,
    zipCode,
    type,
  };
};

const transformHealthProvider = (healthProviders: any) => {
  return healthProviders.map((healthProvider: HealthProvider) => {
    const {
      id,
      organizationName,
      firstName,
      lastName,
      phoneNumber,
      email,
      relationship,
      address,
      comments,
    } = healthProvider;
    return {
      id,
      organizationName,
      firstName,
      lastName,
      phoneNumber,
      email,
      relationship,
      address: transformAddressForHealthProvider(address),
      comments,
    };
  });
};

const transformMedication = (medications: any) => {
  return medications.map((medication: Medication) => {
    const { id, name, strength, type, form, route, healthProviderId } =
      medication;
    return {
      id,
      name,
      strength,
      type,
      form,
      route,
      healthProviderId,
    };
  });
};

const transformHealthConditions = (healthConditions: any) => {
  return healthConditions.map((healthCondition: HealthCondition) => {
    const {
      id,
      condition,
      other,
      hasBeenHospitalized,
      medicationId,
      comments,
    } = healthCondition;
    return {
      id,
      condition,
      other,
      hasBeenHospitalized,
      medicationId,
      comments,
    };
  });
};

// health questions are transformed differently than hwo returned from BE
export const transformHealthQuestions = (
  healthQuestionAnswers: any,
  healthConditionAnswers: HealthQuestionAnswers[],
) => {
  const recordForQuestionAnswers: Record<string, any> = {};
  healthConditionAnswers.forEach((data) => {
    const { answerYesNo, answerText, comments, type } = data;
    recordForQuestionAnswers[data.healthQuestionId] = {
      answerYesNo,
      answerText,
      comments,
      type,
    };
  });

  return healthQuestionAnswers.map((data: Record<string, any>) => {
    const { id, type, questionText, required, commentsRequired } = data;
    const record = recordForQuestionAnswers[`${id}`];
    const answer =
      record?.type === HealthQuestionType.YES_NO
        ? record?.answerYesNo
        : record?.answerText;
    return {
      id,
      type,
      questionText,
      required,
      commentsRequired,
      answer,
      comment: record?.comments,
    };
  });
};

const transformhealthQuestionAnswers = (healthQuestionAnswers: any) => {
  return healthQuestionAnswers.map((healthQuestionAnswer: any) => {
    const { healthQuestionId, answerYesNo, answerText, comments } =
      healthQuestionAnswer;

    return {
      healthQuestionId,
      answerYesNo,
      answerText,
      comments,
      type: healthQuestionAnswer.healthQuestion.type,
    };
  });
};

const setHealthInsurance = (insurance: any) => {
  if (!insurance) {
    return healthInsuranceDefault;
  }
  delete insurance['updatedAt'];
  delete insurance['createdAt'];
  return insurance;
};

const transformHealthRecord = (healthRecord: HealthRecord) => {
  return {
    id: healthRecord.id,
    allergyAnswer: healthRecord.allergyAnswer,
    medicationsAnswer: healthRecord.medicationsAnswer,
    healthConditionAnswer: healthRecord.healthConditionAnswer,
    athleticPhysicalAnswer: healthRecord.athleticPhysicalAnswer,
    allergies: transformAllergies(healthRecord.allergies),
    emergencyContact: healthRecord.emergencyContact,
    medications: transformMedication(healthRecord.medications),
    providers: transformHealthProvider(healthRecord.providers),
    healthConditions: transformHealthConditions(healthRecord.healthConditions),
    healthQuestionAnswers: transformhealthQuestionAnswers(
      healthRecord.healthQuestionAnswers,
    ),
    immunizationRecords: healthRecord.immunizationRecords,
    otcMedication: healthRecord.otcMedication,
    insurance: setHealthInsurance(healthRecord.insurance),
    consentForTreatment: healthRecord.consentForTreatment,
    athleticPhysical: null
  };
};

export const transformStudent = (student: any) => {
  return {
    id: student.id,
    siteId: student.siteId,
    firstName: student.firstName ?? '',
    lastName: student.lastName ?? '',
    dateOfBirth: student.dateOfBirth
      ? new Date(student.dateOfBirth).toLocaleDateString('en-CA')
      : '',
    gender: student.gender ?? '',
    citizenship: student.citizenship ?? '',
    address: transformAddressForDemographics(student.address),
    healthRecord: transformHealthRecord(student.healthRecord),
  };
};

// below are utilities that used for track object & save object
export const deleteFromArray = (
  array: any[],
  index: number,
  deleteCount = 1,
) => {
  array.splice(index, deleteCount);
};

export const deleteBasedOnTrackObj = (
  id: number,
  list: any[],
  localRecordList: SaveObject,
  trackObject: TrackObject,
) => {
  const type = trackObject[id]?.type;
  deleteFromArray(list, trackObject[id].index);
  if (type) {
    const typeIndex = trackObject[id][type];
    for (let i = typeIndex + 1; i < localRecordList[type].length; i++) {
      trackObject[localRecordList[type][i]['id']][type] -= 1;
    }
    deleteFromArray(localRecordList[type], typeIndex);
  }
  delete trackObject[id];
  list.forEach((l, index) => {
    trackObject[l.id].index = index;
  });
  if (type === 'create') return;
  localRecordList.delete.push(id);
};

export const createBasedOnTrackObject = (
  data: any,
  mainList: any,
  saveObject: SaveObject,
  trackObject: TrackObject,
) => {
  mainList.push({ ...data });
  saveObject.create.push({ ...data });
  trackObject[data.id] = {
    index: mainList.length - 1,
    type: 'create',
    create: saveObject.create.length - 1,
    update: -1,
  };
};

export const updateBasedOnTrackObject = (
  data: any,
  mainList: any,
  saveObject: SaveObject,
  trackObject: TrackObject,
) => {
  mainList[trackObject[data.id].index] = { ...data };
  if (trackObject[data.id].type === 'create') {
    saveObject.create[trackObject[data.id].create] = { ...data };
  } else {
    if (trackObject[data.id].type === 'update') {
      saveObject.update[trackObject[data.id].update].updateBody = { ...data };
    } else {
      saveObject.update.push({
        id: data.id,
        updateBody: { ...data },
      });
      trackObject[data.id].type = 'update';
      trackObject[data.id].update = saveObject.update.length - 1;
    }
  }
};

// utils to format data

export const deleteKeyFromObject = (key: string, objectList: any[]) => {
  objectList.forEach((item) => {
    if (item.hasOwnProperty(key)) {
      delete item[key];
    }
  });
};

export const deleteIdFromCreateAndUpdateInSaveObject = (
  saveObj: SaveObject,
) => {
  deleteKeyFromObject('id', saveObj.create);
  saveObj.update.forEach((o) => {
    delete o.updateBody['id'];
  });
};

const deleteEmptyFieldsFromObject = (object: any) => {
  for (const key in object) {
    if (object[key] === '') {
      delete object[key];
    }
  }
};

export const removeUnfilledValuesFromData = (saveObj: SaveObject) => {
  saveObj.create.forEach((c) => {
    deleteEmptyFieldsFromObject(c);
  });
  saveObj.update.forEach((u) => {
    deleteEmptyFieldsFromObject(u.updateBody);
  });
};
