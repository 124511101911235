import { Component, Input } from '@angular/core';

@Component({
  selector: 'student-profile-card',
  templateUrl: './student-profile-card.component.html',
  styleUrls: ['./student-profile-card.component.scss'],
})
export class StudentProfileCardComponent {
  @Input() studentName: string = '';
  @Input() avantID: string = '';
  @Input() studentID: string | number = '';
  @Input() dob: string | null = '';
  @Input() gradeYear: number = 0;
  @Input() gender: string | null = '';
  @Input() studentImageUrl: string = 'assets/images/users/avatar-7.jpg';
  @Input() student: any;

  ngOnChanges() {
    console.log(this.student, 'student');
  }
}
