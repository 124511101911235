<div id="layout-wrapper">
    <app-topbar configuration="parent"></app-topbar>
    <app-horizontal-topbar configuration="parent"></app-horizontal-topbar>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>