<div class="d-flex align-items-center border border-dashed p-2 rounded">
  <div class="flex-shrink-0 avatar-sm">
      <div class="avatar-title bg-light rounded">
          <i class="ri-file-zip-line fs-20 text-primary"></i>
      </div>
  </div>
  <div class="flex-grow-1 ms-3">
      <h6 class="mb-1"><a class="text-body">{{fileObject.fileName}}</a></h6>
      <small class="text-muted">{{fileObject.fileSizeInKbs}} KB</small>
  </div>
  <div class="hstack gap-3 fs-16">
      <a href="{{fileObject.fileUrl}}" target="_blank" class="text-muted"><i class="ri-download-2-line"></i></a>
      <a (click)="delete()" class="text-muted"><i class="ri-delete-bin-line"></i></a>
  </div>
</div>