import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/auth.models';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getUsersFamily() {
    return this.http.get<any>(`${environment.backendUrl}/student/family`); 
  }

  resetUserPassword(user: User, newPassword: string) {
    return this.http.post<any>(`${environment.backendUrl}/auth/admin/reset-user-password`, {
      userId: user.id,
      password: newPassword
    });
  }

  addStudentToParent(familyId: string) {
    return this.http.post<any>(`${environment.backendUrl}/users/family/student`, {
      familyId
    });
  }

  addParentToFamily(args: {
    siteId: number,
    firstName: string,
    lastName: string,
    email: string,
    sendActivationEmail: boolean,
    familyId: string
  }): Observable<any> {
    let { siteId, firstName, lastName, email, sendActivationEmail, familyId } = args;
    return this.http.post<any>(`${environment.backendUrl}/users/family/${args.familyId}/parent`, {
      siteId,
      firstName,
      lastName,
      email,
      familyId,
      sendActivationEmail
    });
  }

  /***
   * Get All Sys Admins
   */
  getSysAdmins(): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/users/sysadmins`);
  }

  /***
   * Get Users
   */
  getUsers(args?: {
    search?: string;
    status?: string;
    role?: string;
    orderByField?: string;
    orderByDirection?: string;
    take?: number;
    skip?: number;
  }): Observable<any> {
    console.log(args);
    let query,
      params: {
        search?: string;
        status?: string;
        role?: string;
        take?: number;
        skip?: number;
        orderByField?: string;
        orderByDirection?: string;
      } = {
        orderByField: 'lastName',
        orderByDirection: 'desc',
      };
    if (args) {
      if (args.search) params.search = args.search;
      if (args.role) params.role = args.role.toUpperCase();
      if (args.status) params.status = args.status;
      if (args.take) params.take = args.take;
      if (args.orderByDirection)
        params.orderByDirection = args.orderByDirection;
      if (args.orderByField) params.orderByField = args.orderByField;
      if (args.skip || args.skip === 0) params.skip = args.skip;
    }
    return this.http.get<any>(`${environment.backendUrl}/users/query`, {
      params
    });
  }

  /***
   * Get Users For a Site
   */
   getUsersForSite(args?: {
    siteId: number;
    search?: string;
    status?: string;
    role?: string;
    orderByField?: string;
    orderByDirection?: string;
    take?: number;
    skip?: number;
    includeStudents?: boolean;
  }): Observable<any> {
    console.log(args);
    let query,
      params: {
        search?: string;
        status?: string;
        role?: string;
        take?: number;
        skip?: number;
        orderByField?: string;
        orderByDirection?: string;
        includeStudents?: boolean;
      } = {
        orderByField: 'lastName',
        orderByDirection: 'desc',
      };
    if (args) {
      if (args.search) params.search = args.search;
      if (args.role) params.role = args.role.toUpperCase();
      if (args.status) params.status = args.status;
      if (args.take) params.take = args.take;
      if (args.orderByDirection)
        params.orderByDirection = args.orderByDirection;
      if (args.orderByField) params.orderByField = args.orderByField;
      if (args.skip || args.skip === 0) params.skip = args.skip;
      if (args.includeStudents) params.includeStudents = true;
    }
    return this.http.get<any>(`${environment.backendUrl}/users/${args?.siteId}/query`, {
      params
    });
  }

  /***
   * Create Admin User
   */
  createAdminUser(values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/users/systemadmin`,
      values,
    );
  }

  /***
   * Edit User
   */
  EditUser(values: any, id: any): Observable<any> {
    console.log('edi user', id);
    return this.http.patch<any>(
      `${environment.backendUrl}/users/${id}`,
      values,
    );
  }

  /***
   * Create Faculty
   */
  createFaculty(values: any, role: string): Observable<any> {
    console.log('role', role);
    return this.http.post<any>(
      `${environment.backendUrl}/users/${role.toLowerCase()}`,
      {...values, ...values.user},
    );
  }

  /***
   * Get System Admin by Id
   */
  getUserById(id: any): Observable<any> {
    return this.http.get<any>(`${environment.backendUrl}/users/${id}`);
  }

  /***
   * Delete a User
   */
  deleteUser(id: any, data: any): Observable<any> {
    return this.http.delete<any>(`${environment.backendUrl}/users/` + id, {
      body: data,
    });
  }

  /***
   * Resend Activation Email
   */
  ResendActivationEmail(data: any): Observable<any> {
    return this.http.post<any>(
      `${environment.backendUrl}/users/resend-activation-email`,
      data,
    );
  }

  /***
   * Facked User Register
   */
  register(user: User) {
    return this.http.post(`/users/register`, user);
  }
}
