export enum AllergySeverity {
  MILD = 'MILD',
  MODERATE = 'MODERATE',
  SEVERE = 'SEVERE',
  LIFE_THREATENING = 'LIFE_THREATENING',
}

export enum AllergyAllergen {
  FOOD = 'FOOD',
  MEDICATIONS = 'MEDICATIONS',
  INSECT_BITE_OR_STING = 'INSECT_BITE_OR_STING',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  MATERIALS = 'MATERIALS',
  OTHER = 'OTHER',
}

export enum AllergyReactionType {
  NAUSEA = 'NAUSEA',
  CLOSED_THROAT = 'CLOSED_THROAT',
  RASH = 'RASH',
  VOMITING = 'VOMITING',
  OTHER = 'OTHER',
  NONE = '',
}

export enum EHealthInsurancePolicyType {
  HMO = 'HMO',
  PPO = 'PPO',
  POS = 'POS',
  EPO = 'EPO',
  HDHP = 'HDHP',
}

export enum ERelationshipToStudent {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  GRANDPARENT = 'GRANDPARENT',
  GUARDIAN = 'GUARDIAN',
  SIBLING = 'SIBLING',
  AUNT = 'AUNT',
  UNCLE = 'UNCLE',
  OTHER = 'OTHER',
}

export enum AddressType {
  SCHOOL = 'SCHOOL',
  HOME = 'HOME',
}

export enum MedicationType {
  OTC = 'OTC',
  PRESCRIPTION = 'PRESCRIPTION',
}

export enum HealthProviderRelationShip {
  PRIMARY_CARE_PHYSICIAN = 'PRIMARY_CARE_PHYSICIAN',
  SPECIALIST = 'SPECIALIST',
  THERAPIST = 'THERAPIST',
  OTHER = 'OTHER',
}

export enum HealthConditionConditions {
  INJURY = 'INJURY',
  CHRONIC = 'CHRONIC',
  BEHAVIORIAL = 'BEHAVIORIAL',
  OTHER = 'OTHER',
}

export enum AnswerYesNo {
  YES = 'YES',
  NO = 'NO',
}

export enum HealthQuestionType {
  YES_NO = 'YES_NO',
  FREE_TEXT = 'FREE_TEXT',
}
export interface Allergy {
  id: number;
  allergen: string;
  name: string;
  severity: string;
  hasBeenHospitalized: boolean;
  dateOfLastReaction: string;
  comments: string;
  medicationId: string;
  typeOfReaction: string;
  // TOTO: fields left intentionally, will add in future
  otherAllergenName?: string;
  otherReactionName?: string;
}

export interface Address {
  id: number;
  street: string;
  street2?: string | null;
  city: string;
  state: string;
  zipCode?: string | null;
  type?: AddressType;
}

export interface EmergencyContact {
  id: number;
  firstName: string;
  lastName: string;
  relationship: ERelationshipToStudent;
  email?: string;
  phoneNumber: string;
  address: Partial<Address>;
}

export interface Medication {
  id: number;
  name: string;
  strength: string;
  type: MedicationType;
  form: string;
  route: string;
  healthProviderId: number;
}

export class HealthProvider {
  id: number;
  organizationName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: HealthProviderRelationShip;
  address: Address;
  comments: string;
}

export interface HealthCondition {
  id: number;
  condition: HealthConditionConditions;
  other: string;
  hasBeenHospitalized: boolean;
  medicationId: number;
  comments: string;
}
// interface for questions that are fetched
export interface HealthQuestion {
  id: number;
  type: `${HealthQuestionType}`;
  questionText: string;
  required: boolean;
  commentsRequired: boolean;
  answer: string;
  comment: string;
}

// interface for questions that are attached to student
export interface HealthQuestionAnswers {
  healthQuestionId: number;
  answerYesNo: AnswerYesNo;
  answerText: string;
  comments: string;
  type: `${HealthQuestionType}`;
}

export interface HealthInsurance {
  insuranceCompany: string;
  policyType: EHealthInsurancePolicyType | '';
  groupId: string;
  memberId: string;
  policyHolderFirstName: string;
  policyHolderLastName: string;
  relationshipWithStudent: ERelationshipToStudent | '';
  insuranceDocuments: string[];
}
export interface HealthRecord {
  id: number;
  allergyAnswer: AnswerYesNo | null;
  medicationsAnswer: AnswerYesNo | null;
  healthConditionAnswer: AnswerYesNo | null;
  athleticPhysicalAnswer: AnswerYesNo | null;
  allergies: Allergy[];
  immunizationRecords: ImmunizationRecord;
  emergencyContact: EmergencyContact[] | null; // this is always array, fix ? in refactor PR
  medications: Medication[];
  providers: HealthProvider[];
  otcMedication: OtcMedication | null;
  healthConditions: HealthCondition[];
  healthQuestionAnswers: HealthQuestionAnswers[];
  insurance: HealthInsurance;
  consentForTreatment: ConsentForTreatment | null;
  athleticPhysical: AthleticPhysical | null;
}

export interface AthleticPhysical {
  id: number;
  fileUrls: String[];
  fileNames: String[];
  createdByName: String;
  createdByEmail: String;
  createdAt: String;
  updatedAt: String;
  files: UploadedFile[] | null;
}

export interface UploadedFile {
  id: number;
  fileUrl: string;
  fileName: string;
  fileSizeInKbs: number;
  mimetype: string | null;
  athleticPhysical: AthleticPhysical | null;
  athleticPhysicalId: number;
}

export interface ConsentForTreatment {
  createdAt: string;
  createdByName: string;
  healthRecordId: number;
  id: number;
  signature: string;
  updatedAt: string;
  signatureDate: string;
}

export interface OtcMedication {
  id?: number;
  tags: string[];
  signature: string;
}

export interface ImmunizationRecord {
  fileUrls: string[];
  id: number;
}

export interface Demographics {
  firstName: string;
  lastName: string;
  dateOfBirth: string | null;
  gender: string;
  citizenship: string;
}
export interface Student extends Demographics {
  id: number;
  siteId: number;
  healthRecord: HealthRecord;
  address: Address[];
}

export const healthInsuranceDefault: HealthInsurance = {
  insuranceCompany: '',
  policyType: '',
  relationshipWithStudent: '',
  groupId: '',
  memberId: '',
  policyHolderFirstName: '',
  policyHolderLastName: '',
  insuranceDocuments: [],
};

export const studentDefault: Student = {
  id: 0,
  siteId: 0,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
  citizenship: '',
  address: [],
  healthRecord: {
    id: 0,
    allergyAnswer: null,
    medicationsAnswer: null,
    healthConditionAnswer: null,
    athleticPhysicalAnswer: null,
    allergies: [],
    emergencyContact: null,
    medications: [],
    providers: [],
    healthConditions: [],
    healthQuestionAnswers: [],
    otcMedication: null,
    immunizationRecords: {
      id: 0,
      fileUrls: [],
    },
    insurance: healthInsuranceDefault,
    consentForTreatment: null,
    athleticPhysical: null
  },
};

// reusable select options
export const relationToStudentSelectOptions = [
  {
    text: 'Mother',
    value: ERelationshipToStudent.MOTHER,
  },
  {
    text: 'Father',
    value: ERelationshipToStudent.FATHER,
  },
  {
    text: 'Grandparent',
    value: ERelationshipToStudent.GRANDPARENT,
  },
  {
    text: 'Guardian',
    value: ERelationshipToStudent.GUARDIAN,
  },
  {
    text: 'Sibling',
    value: ERelationshipToStudent.GUARDIAN,
  },
  {
    text: 'Aunt',
    value: ERelationshipToStudent.AUNT,
  },
  {
    text: 'Uncle',
    value: ERelationshipToStudent.UNCLE,
  },
  {
    text: 'Other',
    value: ERelationshipToStudent.OTHER,
  },
];

export const relationNameFromValue = {
  [ERelationshipToStudent.MOTHER as string]: 'Mother',
  [ERelationshipToStudent.FATHER as string]: 'Father',
  [ERelationshipToStudent.GRANDPARENT as string]: 'Grandparent',
  [ERelationshipToStudent.GUARDIAN as string]: 'Guardian',
  [ERelationshipToStudent.SIBLING as string]: 'Sibling',
  [ERelationshipToStudent.AUNT as string]: 'Aunt',
  [ERelationshipToStudent.UNCLE as string]: 'Uncle',
  [ERelationshipToStudent.OTHER as string]: 'Other',
};

export const addressTypeSelectOptions = [
  {
    text: 'Home',
    value: AddressType.HOME,
  },
  {
    text: 'School',
    value: AddressType.SCHOOL,
  },
];
// interfaces for api
interface updateAllergyBody {
  id: number;
  updateBody: Allergy;
}
export interface SaveAllergyObject {
  allergyAnswer: boolean | null;
  delete: number[];
  create: Allergy[];
  update: updateAllergyBody[];
}

type UpdateBody = {
  id: number;
  updateBody: Record<string, any>;
};
export interface SaveObject {
  delete: number[];
  create: Record<string, any>[];
  update: UpdateBody[];
}

// this DS is to track record of all scenario that happened while working with SaveObject interface
interface TrackObjectItem {
  index: number;
  type: 'create' | 'update' | '';
  create: number;
  update: number;
}

export interface TrackObject {
  [id: number]: TrackObjectItem;
}
