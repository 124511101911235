<div class="card mb-0">
  <div class="card-body p-4">
    <div class="text-center">
      <div class="profile-user position-relative d-inline-block mx-auto mb-4">
        <img src="{{studentImageUrl}}" class="rounded-circle avatar-xl img-thumbnail user-profile-image"
          alt="user-profile-image" />
        <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
          <input id="profile-img-file-input" type="file" class="profile-img-file-input" />
          <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
            <span class="avatar-title rounded-circle bg-light text-body">
              <i class="ri-camera-fill"></i>
            </span>
          </label>
        </div>
      </div>
      <h5 class="fs-16 mb-4">{{studentName}}</h5>
    </div>
    <div class="d-flex gap-1 mb-2" *ngIf="!!avantID">
      <p class="fs-14 mb-0 font-bold">Avant ID:</p>
      <p class="text-muted mb-0">{{avantID}}</p>
    </div>
    <div class="d-flex gap-1 mb-2" *ngIf="!!studentID">
      <p class="fs-14 mb-0 font-bold">Student ID:</p>
      <p class="text-muted mb-0">{{studentID}}</p>
    </div>
    <div class="d-flex gap-1 mb-2" *ngIf="!!dob">
      <p class="fs-14 mb-0 font-bold">Date of Birth:</p>
      <p class="text-muted mb-0">{{dob}}</p>
    </div>
    <div class="d-flex gap-1 mb-2" *ngIf="!!gradeYear">
      <p class="fs-14 mb-0 font-bold">Grade:</p>
      <p class="text-muted mb-0">{{gradeYear}}</p>
    </div>
    <div class="d-flex gap-1 mb-2" *ngIf="!!gender">
      <p class="fs-14 mb-0 font-bold">Gender:</p>
      <p class="text-muted mb-0">{{gender}}</p>
    </div>
  </div>
</div>