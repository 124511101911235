import { User, UserSiteRole } from "../models/user.models";

export function getDestinationUrl(returnUrl: string, user: User): { url: string, queryParams?: any } {
    let destination;
    if(returnUrl && returnUrl !== '/' && returnUrl !== '') {
      destination = { url: returnUrl };
    } else if(user.passwordResetRequired) {
      destination = {
        url: 'auth/pass-create/basic',
        queryParams: {
          token: user.newPasswordTempToken
        }
      }
    } else {
      let hasJarvis = false;
      let hasNurse = false;
      let selectedSchool;
      let nurseSiteId = "-1";
      user.siteRoles?.forEach((s: UserSiteRole) => {
        if(s.roles.indexOf('NURSE') === 0 || s.roles.indexOf('SYSTEMADMIN') === 0) {
          hasNurse = true;
          selectedSchool = s.site;
          nurseSiteId = s.site.id;
        }
        if(s.roles.indexOf('INTERNAL_ADMIN') === 0) hasJarvis = true;
      });
      if(hasJarvis) destination = { url: '/jarvis' };
      else if(hasNurse && selectedSchool) {
        destination = { url: `/nurse/${nurseSiteId}` };
      }
      else destination = { url: '/parent' };
    }
    console.log('[getDestinationUrl]: destination = ', destination);
    return destination;
  };