import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingInputComponent } from './floating-input/floating-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SignaturePadModule } from 'angular2-signaturepad';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { SignatureComponent } from './signature-component/signature-component.component';
import { FloatingSelectComponent } from './floating-select/floating-select.component';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

// icons
import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';

// Feather Icon
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

// Ck Editer
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// simplebar

import { SimplebarAngularModule } from 'simplebar-angular';

// Mask
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
  IConfig,
} from 'ngx-mask';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { FileCardComponent } from './file-card/file-card.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { StudentAutocompleteComponent } from './student-autocomplete/student-autocomplete.component';
import { MailboxComponent } from './mailbox/mailbox.component';
import { NgbAccordionModule, NgbCollapseModule, NgbDropdownModule, NgbNavModule, NgbPaginationModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StudentProfileCardComponent } from './student-profile-card/student-profile-card.component';

@NgModule({
  declarations: [
    FloatingInputComponent,
    SignatureComponent,
    FloatingSelectComponent,
    QuestionnaireComponent,
    FileCardComponent,
    StudentAutocompleteComponent,
    MailboxComponent,
    StudentProfileCardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularSignaturePadModule,
    NgxUsefulSwiperModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AutocompleteLibModule,
    CKEditorModule,
    SimplebarAngularModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbProgressbarModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbCollapseModule,
    FeatherModule.pick(allIcons),
  ],
  providers: [provideNgxMask()],
  exports: [
    FloatingInputComponent,
    SignatureComponent,
    FloatingSelectComponent,
    QuestionnaireComponent,
    FileCardComponent,
    StudentAutocompleteComponent,
    MailboxComponent,
    StudentProfileCardComponent,
  ],
})
export class SharedUiModule {

  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
