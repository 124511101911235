import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { Student } from 'src/app/core/models/student.model';
import { StudentService } from 'src/app/core/services/student.service';

@Component({
  selector: 'app-student-autocomplete',
  templateUrl: './student-autocomplete.component.html',
})
export class StudentAutocompleteComponent implements OnInit, OnChanges {
  @Input('siteId') siteId: number;
  @Input('placeholder') placeholder: string;
  @Input('label') label: string;
  @Input('idsToFilter') idsToFilter: number[] = [];
  @Output() onSelect: EventEmitter<Student> = new EventEmitter();
  @ViewChild('auto') auto: AutocompleteComponent;

  searchValue: string;
  studentArrForView: Student[];
  studentsResult: Student[] = [];

  constructor(private studentService: StudentService) {}

  ngOnInit(): void {
    console.log('ngOnInit idsToFilter: ', this.idsToFilter);
    this.fetchData();
  }

  ngOnChanges() {
    if(this.studentsResult) {
      this.studentArrForView = this.studentsResult
        .filter(this.filterIdsFromArray(this.idsToFilter))
        .map(this.studentArrDecorators);
    }
  }

  fetchData() {
    this.studentService
      .findStudentsForSiteByName({
        siteId: this.siteId,
      })
      .subscribe((students) => {
        this.studentsResult = students;
        this.studentArrForView = students
          .filter(this.filterIdsFromArray(this.idsToFilter))
          .map(this.studentArrDecorators);
      });
  }

  studentArrDecorators(s: Student) {
    s.name = s.firstName + ' ' + s.lastName;
    return s;
  }

  filterIdsFromArray(arr: number[]) {
    return (item: Student) => {
      return arr.indexOf(item.id) === -1;
    }
  }

  onFocused($event: void) {
    // throw new Error('Method not implemented.');
  }
  onChangeSearch($event: any) {
    // throw new Error('Method not implemented.');
  }
  selectEvent($event: any) {
    console.log('selected ', $event);
    this.onSelect.emit($event);
    console.log('selectEvent auto: ', this.auto);
    this.auto.clear();
  }

  onChange: (val: string) => void;
  onTouched: () => void;

  setValue(e: Event) {
    this.onChange((e.target as HTMLInputElement).value ?? '');
  }
  keyword = 'firstName';
  // Fruit Data
  public Fruit = [
    {
      id: 1,
      firstName: 'Apple',
      lastName: 'Apple',
    },
    {
      id: 2,
      firstName: 'Banana',
      lastName: 'Banana',
    },
    {
      id: 3,
      firstName: 'Blueberry',
      lastName: 'Blueberry',
    },
    {
      id: 4,
      firstName: 'Cherry',
      lastName: 'Cherry',
    },
    {
      id: 5,
      firstName: 'Coconut',
      lastName: 'Coconut',
    },
    {
      id: 6,
      firstName: 'Kiwi',
      lastName: 'Kiwi',
    },
    {
      id: 7,
      firstName: 'Lemon',
      lastName: 'Lemon',
    },
    {
      id: 8,
      firstName: 'Lime',
      lastName: 'Lime',
    },
    {
      id: 9,
      firstName: 'Mango',
      lastName: 'Mango',
    },
    {
      id: 10,
      firstName: 'Orange',
      lastName: 'Orange',
    },
  ];
}
