import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing: boolean;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor(private authenticationService: AuthenticationService, 
    private router: Router,) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response) => {
        console.log(response.error, response.error.message);
        let returnUrl = this.router.url;
        console.log(`is401ResponseErr: ${is401ResponseErr(response)}`);
        console.log(`is2faResponseErr: ${is2faResponseErr(response)}`);
        console.log(`!isLoginOrLogoutRequest: ${!isLoginOrLogoutRequest(response)}`);
        if (is401ResponseErr(response)) {
          if (is2faResponseErr(response)) {
            console.log('Intercepted 2FA error. redirecting to get 2FA code. return url = ', returnUrl);
            let user = this.authenticationService.currentUser();
            if(!user) {
              const error = response.error.message || response.statusText;
              return throwError(error);
            }
            let bits = user.email.split('@');
            let mfaError = {
              otpAdress: user.email,
              multiFactorHint: bits[0].replace(/^(.{2})[^@]+/, "$1xxx")+'@'+bits[1].replace(/^(.{2})[^@]+/, "$1xxx")
            }
            this.router.navigate(['/auth/twostep/basic'],
            { queryParams: { returnUrl },
              state: {
                errorObject: mfaError,
                destinationUrl: returnUrl
              }
            });
          } else if (isHttpResponseErr(response)) {
            console.log('This error is a HttpErrorResponse type. Handle now');
            if(isLoginOrLogoutRequest(response)) {
              console.log('The error is for login or logout API, ignore');
            } else {
              console.log('the request meets criteria to try to relogin with the refresh token.');
              // attempt to get reauthenticated with the embedded refresh token if one is available
              if(this.authenticationService.getRememberMe()) {
                return this.handle401Error(request, next);
              } else {
                this.authenticationService.logout();
                this.router.navigate(['auth/login'],
                { queryParams: { returnUrl } });
              }
              // if it fails, log out and start over.
              // auto logout if 401 response returned from api
            }
          } else {
            console.log('This error doesnt match any criteria... ', response);

          }

        }
        const error = response.error.message || response.statusText;
        return throwError(error);
      }),
    );
  }

  private getClonedRequest(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        // Authorization: `Bearer ${token}`,
      },
    });
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      
      return this.authenticationService.refreshToken().pipe(
        switchMap((user: any) => {
          console.log('handling 401 error, refresh response switchmap = ', user);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(user);
          return next.handle(this.getClonedRequest(request, user));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((user: any) => user && user.id != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.getClonedRequest(request, jwt));
        })
      );
    }
  }
}
function isLoginOrLogoutRequest(request: HttpRequest<any>) {
  return request.url.includes('login') || request.url.includes('logout');
}

function isHttpResponseErr(response: any) {
  return response instanceof HttpErrorResponse;
}

function is2faResponseErr(response: any) {
  return response.error?.message?.indexOf('2FA') === 0;
}

function is401ResponseErr(response: any) {
  return response.status === 401;
}

