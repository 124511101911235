import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

// Auth Services
import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { environment } from '../../../environments/environment';
import { getDestinationUrl } from '../helpers/url-helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser();
    console.log('authenticationService', currentUser);
    if (currentUser) {
      // logged in so return true
      console.log('trying to go to: ', state.url);
      let { url, queryParams } = getDestinationUrl('', currentUser);
      console.log('shouldve navigated to url: ', url);
      const cleanUrl = this.cleanUrlParams(state.url);
      if(cleanUrl === '/' || cleanUrl === '/nurse') {
        this.router.navigate([url], queryParams);
      }
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  cleanUrlParams(url: string) {
    if(url.indexOf('?') < 1) return url;
    else return url.substring(0, url.indexOf('?'));
  }
}
