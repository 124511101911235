import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type optionValue = string | boolean | null;
interface QuestionnaireOptions {
  text: string;
  value: optionValue;
}

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuestionnaireComponent,
      multi: true,
    },
  ],
})
export class QuestionnaireComponent implements OnInit, ControlValueAccessor {
  @Input() question = '';
  @Input() name = '';
  @Input('options') questionOptions: QuestionnaireOptions[] = [];
  @Input() showClearButton = false;
  @Input() classes = '';
  @Input() questionClasses = '';
  @Input() optionsClasses = '';
  @Input() buttonClasses = '';
  @Input() id = `id-${Math.random().toString().replace('.', '')}-${Date.now()}`;
  @Input() required = false;

  value: optionValue = null;
  disabled = false;
  onChange: (val: optionValue) => void;
  onTouched: () => void;

  constructor() {
    // no-op
  }

  ngOnInit(): void {
    // no-op
  }

  writeValue(val: any): void {
    this.value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  setValue(val: optionValue) {
    this.onChange(val);
    this.value = val;
  }
}
