<div class="d-flex align-items-center py-3" [class]="classes">
  <p *ngIf="question" [class]="questionClasses" class="m-0 me-4">
    <span *ngIf="required" class="text-danger">*</span>
    {{question}}
  </p>
  <div class="d-flex align-items-center" [class]="optionsClasses">
    <div class="form-check mx-2" *ngFor="let option of questionOptions; let i = index;">
      <input class="form-check-input" type="radio" [value]="option.value" [checked]="value === option.value"
        [name]="name" [id]="'check-' + i + id" (change)="setValue(option.value)" />
      <label class="form-check-label" [for]="'check-' + i + id">
        {{option.text}}
      </label>
    </div>
    <button *ngIf="showClearButton" type="button" [class]="buttonClasses"
      class="btn btn-ghost-primary waves-effect waves-light mx-3" (click)="setValue(null)">
      Clear
    </button>
  </div>
</div>