import { Address } from './address.models';
import { SiteType } from './enum';
import { Tag } from './tag.models';
import { User } from './user.models';

export interface Site {
  id: string;
  name: string;
  phone: string;
  website: string;
  logoUrl: string;
  lastLogin: string;
  // userSiteRoles UserSiteRole[]
  // students      Student[]
  // tenant        Tenant         @relation(fields: [tenantId], references: [id])
  tenantId: number;
  metadata: SiteMetadata;
  metadataId?: number;
  addresses: Address[];
  healthRequirements: HealthRequirements;
  productModules: ProductModules[];
  startDate: string;
  endDate: string;
  //  nurse
  // metadata:{
  //   nurseTitle: string;
  //   nurseFirstName: string;
  //   nurseLastName: string;
  //   nurseEmail: string;
  //   nursePhone: string;
  // }

  avantId: string;

  users: User[];
  // groups    Group[]
  tags: Tag[];
  createdAt: string;
  _count?: {
    students: number;
    users: number;
    groups: number;
  };
}

export enum ProductModules {
  MESSAGING = 'MESSAGING'
}

export interface HealthRequirementsIndex {
  allergiesRequired: 'allergiesRequired';
  providersRequired: 'providersRequired';
  healthConditionsRequired: 'healthConditionsRequired';
  immunizationsRequired: 'immunizationsRequired';
  insuranceRequired: 'insuranceRequired';
  treatmentConsentRequired: 'treatmentConsentRequired';
  healthQuestionsRequired: 'healthQuestionsRequired';
  medicationsRequired: 'medicationsRequired';
  schoolPhyiscalRequired: 'schoolPhyiscalRequired';
  athleticPhyiscalRequired: 'athleticPhyiscalRequired';
}
export interface HealthRequirements {
  id: number;
  site?: Site;
  allergiesRequired: boolean;
  providersRequired: boolean;
  availableOtcMedications: AvailableOtcMedications[];
  healthConditionsRequired: boolean;
  immunizationsRequired: boolean;
  insuranceRequired: boolean;
  treatmentConsentRequired: boolean;
  healthQuestionsRequired: boolean;
  medicationsRequired: boolean;
  schoolPhyiscalRequired: boolean;
  athleticPhyiscalRequired: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface AvailableOtcMedications {
  id?: number;
  healthRequirements: HealthRequirements[];
  healthRequirementsId: number;
  active: boolean;
  name: string;
}

export interface SiteMetadata {
  id: string;
  numberOfStudents: number;
  type: SiteType;
  site?: Site;
  createdAt: string;
  nurseTitle: string;
  nurseFirstName: string;
  nurseLastName: string;
  nurseEmail: string;
  nursePhone: string;

  // billing
  billingTitle: string;
  billingFirstName: string;
  billingLastName: string;
  billingEmail: string;
  billingPhone: string;

  // support
  supportTitle?: string;
  supportFirstName?: string;
  supportLastName?: string;
  supportEmail?: string;
  supportPhone?: string;

  // sis integration
  sisVendor: string;
  sisKey: string;

  supportCode: string;
}
