import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  user: User | null;
  configForm: FormGroup;
  
  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Avant' },
      { label: 'Parent' },
      { label: 'Profile', active: true },
    ];
   
    this.user = this.authService.currentUser();
    this.configForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      phoneNumber: [''],
    });
    if(this.user) {
      this.configForm.patchValue(this.user);
    }

  }

  changeLogo() {
    Swal.fire('Under construction', 'Not implemented yet', 'info');
  }

  get form() {
    return this.configForm.controls;
  }

  ngOnDestroy() {
    // this.siteSubscription.unsubscribe()
  }

  saveConfiguration() {
    
  }

}
