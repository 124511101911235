import { User } from 'src/app/core/models/auth.models';

export { default as formatDate } from './formatDate';

export class CodeError extends Error {
    status;
    code;
    user;
    constructor(data: { status?: string, code?: string, message: string, user: User }) {
       super(data.message);
       this.status = data.status;
       this.code = data.code;
       this.message = data.message;
       this.user = data.user;
    }
}