import { MenuItem } from './menu.model';

export const JARVIS_MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
  },
  {
    id: 2,
    label: 'MENUITEMS.JARVIS.TEXT',
    icon: 'bx bxs-home',
    link: '/jarvis'
    },
{
  id: 184,
  label: 'MENUITEMS.SCHOOL.TEXT',
  icon: 'bx bxs-bus',
  link: '/jarvis/school/list'
  },
  {
    id: 188,
    label: 'MENUITEMS.JARVIS.LIST.SYSADMINS',
    icon: 'bx bx-user-circle',
    link: '/jarvis/system-admins'
    // subItems: [
    //   {
    //     id: 185,
    //     label: 'MENUITEMS.SCHOOL.LIST.SCHOOLSLIST',
    //     link: '/jarvis/school/list',
    //     parentId: 184,
    //   },
    //   {
    //     id: 186,
    //     label: 'MENUITEMS.SCHOOL.LIST.CREATESCHOOL',
    //     link: '/jarvis/school/new',
    //     parentId: 184,
    //   },
      // {
      //   id: 187,
      //   label: 'MENUITEMS.SCHOOL.LIST.SCHOOLDETAILS',
      //   link: '/school-details/:id',
      //   parentId: 184,
      // },
    // ],
  },
  
];
