import {
  Component,
  ViewChild,
  forwardRef,
  Input,
  TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
// import { SignaturePad } from 'angular2-signaturepad';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';


function dataURItoBlob(dataURI: string) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([new Blob([ab], { type: mimeString })], 'signature.jpeg');
}

@Component({
  selector: 'app-signature-component',
  templateUrl: './signature-component.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureComponent),
      multi: true,
    },
  ],
})
export class SignatureComponent implements ControlValueAccessor {
  @ViewChild('signature') signaturePad: SignaturePadComponent;
  // @ViewChild('signatureWrapper') signatureParent: any;

  @Input() penColor = 'rgb(0, 0, 0)';
  @Input() bgColor = 'rgb(251, 251, 251)';
  

  options = {
    canvasHeight: 300,
    canvasWidth: 500
  };
  _signature: any = null;
  propagateChange: any = null;
  signedSignatureImage = '';

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    console.log(this.signaturePad);
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  get signature(): any {
    console.log('get signature');
    return this._signature;
  }


  set signature(value: any) {
    console.log('set signature', value);
    this._signature = value ? dataURItoBlob(value) : null;
    this.propagateChange(this.signature);
  }

  resizeCanvas() {
    // const width = this.signatureParent.nativeElement.offsetWidth - 5;
    // this.signaturePad.set('canvasWidth', width);
    // this.signaturePad.set('canvasHeight', width * 0.5625);
    // this.signaturePad.set('penColor', this.penColor);
    // this.signaturePad.set('backgroundColor', this.bgColor);
    // this.signaturePad.clear();
    // this.signedSignatureImage = '';
  }

  public writeValue(value: any): void {
    console.log('writeValue', value);
    if (!value) {
      return;
    }
    this._signature = dataURItoBlob(value);
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  public drawStart(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    console.log('End Drawing');
    this.signature = this.signaturePad.toDataURL();
    this.signedSignatureImage = this.signaturePad.toDataURL();
    console.log(this.signedSignatureImage);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = null;
    this.signedSignatureImage = '';
  }
}
