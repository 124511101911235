<ng-template #content role="document" let-modal>
    <div class="modal-header">
      <h5>Add New User</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        id="close-modal"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <form (ngSubmit)="createUser()" [formGroup]="adminForm">
      <div class="modal-body">
        <div class="row g-2">
          <div class="form-floating">
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              required
              formControlName="school"
              [ngClass]="{
                'is-invalid': submitted && form['school'].errors
              }"
            >
              <option selected>Choose...</option>
              <option value="1">USA</option>
              <option value="2">Brazil</option>
              <option value="3">France</option>
              <option value="4">Germany</option>
            </select>
            <label for="floatingSelect">School</label>
          </div>
          <div class="form-floating">
            <select
              class="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              required
              formControlName="role"
              [ngClass]="{
                'is-invalid': submitted && form['role'].errors
              }"
            >
              <option selected>Choose...</option>
              <option value="1">USA</option>
              <option value="2">Brazil</option>
              <option value="3">France</option>
              <option value="4">Germany</option>
            </select>
            <label for="floatingSelect">Role</label>
          </div>
          <div class="col-lg-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                id="firstName"
                required
                formControlName="firstName"
                placeholder="First Name"
                [ngClass]="{
                  'is-invalid': submitted && form['firstName'].errors
                }"
              />
              <label for="firstName">First Name</label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                id="lastName"
                required
                formControlName="lastName"
                placeholder="Last Name"
                [ngClass]="{
                  'is-invalid': submitted && form['lastName'].errors
                }"
              />
              <label for="lastName">Last Name</label>
            </div>
          </div>
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="emailAdress"
              required
              formControlName="email"
              placeholder="Email Adress"
              [ngClass]="{
                'is-invalid': submitted && form['email'].errors
              }"
            />
            <label for="emailAdress">Email Address</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="formCheck6"
              checked
            />
            <label class="form-check-label" for="formCheck6">
              Send Invite Email to User
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            (click)="modal.close('Close click')"
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary" id="add-btn">
            Create Account
          </button>
        </div>
      </div>
    </form>
  </ng-template>
