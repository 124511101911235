import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss'],
})
export class DashboardStatsComponent implements OnInit {
  // Best Selling data
  @Input() DashboardStats:
    | Array<{
        title: string;
        icon: string;
        value: number;
      }>
    | undefined;

  constructor() {}

  ngOnInit(): void {
    console.log(this.DashboardStats);
  }
}
