<li class="list-group-item d-flex align-items-center" *ngFor="let data of TopPerformers">
    <div class="flex-shrink-0">
        <img src="{{data.image}}" class="avatar-xs" alt="">
    </div>
    <div class="flex-grow-1 ms-3">
        <h6 class="fs-14 mb-1">{{data.coinName}}</h6>
        <p class="text-muted mb-0">${{data.price}} Billions</p>
    </div>
    <div class="flex-shrink-0 text-end">
        <h6 class="fs-14 mb-1">${{data.change}}</h6>
        <p class="fs-12 mb-0" [ngClass]=" { 'text-success': data.profit === 'up', 'text-danger': data.profit === 'down'}"><span *ngIf="data.profit == 'up'">+</span><span *ngIf="data.profit == 'down'">-</span>${{data.balance}} (<span *ngIf="data.profit == 'up'">+</span><span *ngIf="data.profit == 'down'">-</span>{{data.percentage}}%)</p>
    </div>
</li><!-- end -->