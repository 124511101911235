const monthsHash: Record<number, string> = {
  0: 'January',
  1: 'Febuary',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

/**
 * converts any date to this format September 13, 2022 · 09:05am
 * @param date Date
 */
const dateFormat = (date: Date) => {
  let result = new Date(date).toString();
  try {
    const ldate = new Date(date).toLocaleDateString();
    const ltime = new Date(date).toLocaleTimeString();

    const dateSplit = ldate.split('/');

    const year = dateSplit[2];
    const day = dateSplit[1];
    const month = monthsHash[parseInt(dateSplit[0])];

    const timeSplitSpace = ltime.split(' ');

    const sectionOfDay = timeSplitSpace[1];

    const timeSplit = timeSplitSpace[0].split(':');

    const hour = timeSplit[0];
    const minute = timeSplit[1];

    const dateString = `${month} ${day}, ${year}`;
    const timeString = `${hour}:${minute}:${sectionOfDay}`;
    result = `${dateString} · ${timeString}`;
  } catch (_) {}
  return result;
};

export default dateFormat;
