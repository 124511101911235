import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../models/auth.models';
import { User as UserEntity } from '../models/user.models';
import {
  Auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorResolver,
  MultiFactorError,
} from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { CodeError } from 'src/utils';

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {
  user!: User;
  currentUserValue: any;
  initializedMultiFactor = false;
  _multiFactorResolver!: MultiFactorResolver;
  _verificationId!: string;

  constructor(private http: HttpClient) {
    
    // if (environment.defaultauth === 'firebase') {
    //   onAuthStateChanged(this.auth, (user) => {
    //     console.log('onAuthStateChanged', user);
    //     if (user) {
    //       localStorage.setItem('authUser', JSON.stringify(user));
    //     } else {
    //       localStorage.removeItem('authUser');
    //     }
    //   });
    // }
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  // async register(email: string, password: string) {
  //   try {
  //     return await createUserWithEmailAndPassword(this.auth, email, password);
  //   } catch (e: any) {
  //     throw new Error(this.mapAuthCodeToMessage(e?.code));
  //   }
  // }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  async login(email: string, password: string, rememberMe: boolean = false) {
    try {
      return await this.signInWithEmailAndPasswordV2(email, password, rememberMe);
    } catch (e: any) {
      if (e?.code === 'auth/multi-factor-auth-required') {
        throw e;
      }
      throw new Error(this.mapAuthCodeToMessage(e?.code || e));
    }
  }

  async signInWithEmailAndPasswordV2(email: string, password: string, rememberMe: boolean = false) {
    return this.http.post<any>(
      `${environment.backendUrl}/auth/login`,
      { email, password },
    ).toPromise().then((user) => {
      if(rememberMe) {
        this.setRememberMe(rememberMe);
      }
      localStorage.setItem('authUser', JSON.stringify(user));
      return user;
    });
  }

  refreshToken() {
    return this.http.get<any>(
      `${environment.backendUrl}/auth/refresh`
    );
  }

  /**
   * If true, the application will refresh the users JWT token 
   * without prompting the user to login again
   * @param rememberMe boolean to "remember" the user.
   */
  public setRememberMe(rememberMe: boolean) {
    localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
  }

  /**
   * If true, the application will refresh the users JWT token 
   * without prompting the user to login again
   */
  public getRememberMe(): boolean {
    try {
      return JSON.parse(localStorage.getItem('rememberMe') || 'false');
    } catch(e) {
      return false;
    }
  }

  /**
   * Returns the current user
   */
  public currentUser(): UserEntity | null {
    if (!localStorage.getItem('authUser')) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('authUser')!);
    }
  }

  /**
   * Logout the user
   */
  async logout() {
    localStorage.removeItem('authUser');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('activeSite');
    return this.http.post<any>(
      `${environment.backendUrl}/auth/logout`, {}
    );
  }

  // /**
  //  * Reset password
  //  * @param email email
  //  */
  // async resetPassword(email: string) {
  //   try {
  //     return await sendPasswordResetEmail(this.auth, email, {
  //       // eslint-disable-next-line
  //       url: window.location.protocol + '//' + window.location.host + '/auth/login',
  //     });
  //   } catch (e: any) {
  //     throw new Error(this.mapAuthCodeToMessage(e?.code));
  //   }
  // }

  /**
   * Send email with link to reset password
   * @param email email
   */
  sendResetPasswordEmail(email: string) {
    return this.http.post<any>(
      `${environment.backendUrl}/auth/send-reset-password-email`,
      { email },
    ).toPromise();
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(token: string, password: string) {
    return this.http.patch<any>(
      `${environment.backendUrl}/auth/reset-password`,
      { token, password },
    );
  }

  /**
   * Set new password for new user
   * @param token token from new user email
   * @param password password
   */
  setPasswordForNewUser(token: string, password: string) {
    return this.http.post<any>(
      `${environment.backendUrl}/auth/set-password`,
      { token, password },
    );
  }


  async signInWithGoogleV2(credential: string) {
    return this.http.post<any>(
      `${environment.backendUrl}/google/authenticate`,
      { credential },
    ).toPromise().then((user) => {
      if(user?.code) {
        const err = new CodeError({message: 'MFA setup required.', code: user.code, user });
        throw err;
      }
      localStorage.setItem('authUser', JSON.stringify(user));
      return user;
    });
  }

  // async signInWithGoogle() {
  //   const provider = new GoogleAuthProvider();
  //   provider.addScope('email');
  //   try {
  //     return await signInWithPopup(this.auth, provider);
  //   } catch (e: any) {
  //     console.log('service level error sign in with Google, e: ', e);
  //     if (e?.code === 'auth/multi-factor-auth-required') {
  //       throw e;
  //     }
  //     throw new Error(this.mapAuthCodeToMessage(e?.code));
  //   }
  // }

  /**
   * Initialize MFA Sign In & Sends an OTP code.
   */
  // async initializeMultiFactorSignIn(
  //   multiFactorError: MultiFactorError,
  //   buttonId: string,
  // ) {
  //   try {
  //     const multiFactorResolver = getMultiFactorResolver(
  //       this.auth,
  //       multiFactorError,
  //     );
  //     this._multiFactorResolver = multiFactorResolver;
  //     const multiFactorSession = multiFactorResolver.session;

  //     // Send SMS verification code.
  //     const phoneAuthProvider = new PhoneAuthProvider(this.auth);
  //     const phoneInfoOptions = {
  //       multiFactorHint: multiFactorResolver.hints[0],
  //       session: multiFactorSession,
  //     };
  //     this._verificationId = await phoneAuthProvider.verifyPhoneNumber(
  //       phoneInfoOptions,
  //       this.verifyCaptcha(buttonId),
  //     );
  //   } catch (error: any) {
  //     throw new Error(this.mapAuthCodeToMessage(error?.code ?? error));
  //   }
  // }
  
  /**
   * Completes MFA steps & signs-user in
   * @param verificationCode OTP code from user
   * @param enable2faSettingOnUser set user.isTwoFactorAuthEnabled to true on the users profile settings
   */
  async completeMultiFactorSignInV2(options:{
    verificationCode: string,
    rememberDevice: boolean,
    enable2faSettingOnUser: boolean
  }) {
    try {
      const args = { twoFactorAuthenticationCode: options.verificationCode, mode: 'TEXT', rememberDevice: options.rememberDevice };
      if(options.enable2faSettingOnUser) {
        await this.http.post<any>(
          `${environment.backendUrl}/2fa/turn-on`,
          args,
        ).toPromise();
      }
      return this.http.post<any>(
        `${environment.backendUrl}/2fa/authenticate`,
        args,
      ).toPromise().then((user) => {
        localStorage.setItem('authUser', JSON.stringify(user));
        return user;
      });
      // if its not enabled yet, HTTP POST to 2fa/turn-on
      // HTTP POST to 2fa/authenticate
      // save current user in local storage
      // return successfull login
    } catch (error: any) {
      console.log(error);
      throw new Error(this.mapAuthCodeToMessage(error?.code ?? error));
    }
  }

  // /**
  //  * Completes MFA steps & signs-user in
  //  * @param multiFactorResolver
  //  * @param verificationId id of the verification initialized above
  //  * @param verificationCode OTP code from user
  //  */
  // async completeMultiFactorSignIn(
  //   multiFactorResolver: MultiFactorResolver = this._multiFactorResolver,
  //   verificationId: string = this._verificationId,
  //   verificationCode: string,
  // ) {
  //   try {
  //     const phoneAuthCredential = PhoneAuthProvider.credential(
  //       verificationId,
  //       verificationCode,
  //     );
  //     const multiFactorAssertion =
  //       PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
  //     return multiFactorResolver.resolveSignIn(multiFactorAssertion);
  //   } catch (error: any) {
  //     console.log(error);
  //     throw new Error(this.mapAuthCodeToMessage(error?.code ?? error));
  //   }
  // }

  // verifyCaptcha(buttonId: string) {
  //   return new RecaptchaVerifier(buttonId, { size: 'invisible' }, this.auth);
  // }

  /**
   * Convert firebase errorCodes to Human readable error strings.
   */
  mapAuthCodeToMessage(authCode: string) {
    switch (authCode) {
      case 'auth/user-not-found':
        return 'User not found';
      case 'auth/invalid-password':
        return 'Password is not correct';
      case 'auth/wrong-password':
        return 'Password is not correct';
      case 'auth/invalid-email':
        return 'Email is invalid';
      case 'auth/popup-closed-by-user':
        return 'Popup has been closed by user';
      case 'auth/invalid-verification-code':
        return 'Verification code is invalid';
      // Many more authCode mapping here...
      default:
        return authCode;
    }
  }
}
