<app-breadcrumbs
  title="MANAGE SCHOOL SETTINGS"
  [breadcrumbItems]="breadCrumbItems"
></app-breadcrumbs>

<div class="row" *ngIf="this.user">
  <div class="col-xxl-3">
      <div class="card mt-n5">
          <div class="card-body p-4">
              <div class="text-center">
                  <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                    <img *ngIf="user.photoUrl" class="rounded-circle avatar-xl img-thumbnail user-profile-image" src="{{user.photoUrl}}">
                    <ngx-avatar
                    *ngIf="!user.photoUrl"
                      size="40"
                      name="{{
                        user.firstName
                      }}"
                    ></ngx-avatar>
                      <!-- <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image"> -->
                      <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <input id="profile-img-file-input" type="file" class="profile-img-file-input" (click)="changeLogo()">
                          <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                              <span class="avatar-title rounded-circle bg-light text-body">
                                  <i class="ri-camera-fill"></i>
                              </span>
                          </label>
                      </div>
                  </div>
                  <h5 class="fs-16 mb-1">{{user.firstName}} {{user.lastName}}</h5>
                  <!-- <p class="text-muted mb-0">Support Code = {{school.metadata.supportCode}}</p> -->
              </div>
          </div>
      </div><!--end card-->
      <!-- <div class="card">
          <div class="card-body">
              <div class="row text-center">
                  <div class="col-lg-12">
                      <h5 class="card-title mb-2">Support Code</h5>
                  </div>
                  <p class="text-muted mb-2">Use this code when calling support</p>
                  <h6>{{school.metadata.supportCode}}</h6>
              </div>
          </div>
        </div> -->
      <!-- <div class="card">
          <div class="card-body">
              <div class="d-flex align-items-center mb-4">
                  <div class="flex-grow-1">
                      <h5 class="card-title mb-0">Portfolio</h5>
                  </div>
                  <div class="flex-shrink-0">
                      <a href="javascript:void(0);" class="badge bg-light text-primary fs-12"><i class="ri-add-fill align-bottom me-1"></i> Add</a>
                  </div>
              </div>
              <div class="mb-3 d-flex">
                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                      <span class="avatar-title rounded-circle fs-16 bg-dark text-light">
                          <i class="ri-github-fill"></i>
                      </span>
                  </div>
                  <input type="email" class="form-control" id="gitUsername" placeholder="Username" value="@daveadame">
              </div>
              <div class="mb-3 d-flex">
                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                      <span class="avatar-title rounded-circle fs-16 bg-primary">
                          <i class="ri-global-fill"></i>
                      </span>
                  </div>
                  <input type="text" class="form-control" id="websiteInput" placeholder="www.example.com" value="www.velzon.com">
              </div>
              <div class="mb-3 d-flex">
                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                      <span class="avatar-title rounded-circle fs-16 bg-success">
                          <i class="ri-dribbble-fill"></i>
                      </span>
                  </div>
                  <input type="text" class="form-control" id="dribbleName" placeholder="Username" value="@dave_adame">
              </div>
              <div class="d-flex">
                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                      <span class="avatar-title rounded-circle fs-16 bg-danger">
                          <i class="ri-pinterest-fill"></i>
                      </span>
                  </div>
                  <input type="text" class="form-control" id="pinterestName" placeholder="Username" value="Advance Dave">
              </div>
          </div>
      </div>end card -->
  </div><!--end col-->
  <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
              <li [ngbNavItem]="1" class="nav-item">
                  <a ngbNavLink class="nav-link text-body" data-bs-toggle="tab" role="tab">
                    <i class="fas fa-home"></i>
                    Profile Information
                  </a>
                  <ng-template ngbNavContent>
                    <form action="javascript:void(0);" (ngSubmit)="saveConfiguration()" [formGroup]="configForm">
                      <div class="row g-3 mb-4">
                          <div class="col-lg-6">
                            <app-floating-input formControlName="firstName" [type]='"text"' id="firstName"
        label="First Name"></app-floating-input>
                          </div><!--end col-->
                          <div class="col-lg-6">
                            <app-floating-input formControlName="lastName" [type]='"text"' id="lastName"
        label="Last Name"></app-floating-input>
                        </div><!--end col-->
                        <div class="col-lg-6">
                            <app-floating-input formControlName="phoneNumber" [type]='"phoneNumber"' id="phoneNumber"
        label="Mobile Phone Number"></app-floating-input>
                        </div><!--end col-->
                      </div><!--end row-->
                    </form>
                  </ng-template>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
              <div class="tab-content">
                <div [ngbNavOutlet]="customNav"></div>
              </div>
          </div>
      </div>
  </div><!--end col-->
</div><!--end row-->
