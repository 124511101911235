<div class="d-flex flex-row flex-wrap justify-content-between">
  <div *ngFor="let data of DashboardStats">
    <div class="card card-animate" style="min-width: 300px">
      <div class="card-body d-flex flex-row justify-content-between">
        <div class="d-flex flex-column justify-content-between">
          <h5 class="ff-secondary fw-semibold">{{ data.title }}</h5>
          <p>{{ data.value }}</p>
        </div>
        <div>
          <div class="avatar-sm flex-shrink-0">
            <span class="avatar-title bg-soft-info fs-2 rounded">
              <i class="bx bx-{{ data.icon }}" style="color: #6993e7"></i>
            </span>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
  </div>
</div>

<!-- end card-->
