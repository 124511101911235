<div class="form-floating" [class]="classes">
  <input
    [readonly]="readonly"
    *ngIf="type !== 'states' && type !== 'phoneNumber'"
    [type]="type"
    class="form-control"
    [class]="inputClasses"
    [ngClass]="{'is-invalid': invalid}"
    [id]="id"
    [placeholder]="placeholder"
    [value]="value"
    (change)="setValue($event)" />
  
  <!-- Phone number input -->
  <input
    [readonly]="readonly"
    *ngIf="type !== 'states' && type === 'phoneNumber'"
    [type]="type"
    class="form-control"
    [class]="inputClasses"
    [ngClass]="{'is-invalid': invalid}"
    [id]="id"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [mask]="mask"
    (change)="setValue($event)" />
  <!-- States select -->
  <select *ngIf="type === 'states'" class="form-select" (change)="setValue($event)" [value]="value"
    [ngClass]="{'is-invalid': invalid}" [id]="id" [ngModel]="dropdownState">
    <option [ngValue]="" selected="true">Choose</option>
    <option *ngFor="let state of allStates" [value]="state.name">
      {{state.name}}
    </option>
  </select>
  <label [for]="id" [class]="labelClasses">{{label}} <span *ngIf="required" class="text-danger">*</span></label>
  <div class="invalid-feedback" *ngIf="showValidationErrors && controlDir.dirty">
    <p *ngIf="controlDir.errors?.['required']">
      field is required!
    </p>
    <p *ngIf="controlDir.errors?.['email']">
      email is required!
    </p>
  </div>
</div>